import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import { BallTriangle } from  'react-loader-spinner'

import Logo from "../../Assets/images/Riz primary logo.png"

class Login extends Component {
    state = { 
        showPasswordText: 'See', 
        showPassword1: false,
        showPasswordText1: 'See', 
        showPassword2: false,
        userCategory: "",
        display: "none"
     } 

     async componentDidMount(){
         const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/dashboard');  
        }else {
            const checkCategory = sessionStorage.getItem('user');
            if(!checkCategory){
                this.props.history.push('/category');
            }
            this.setState({ userCategory: checkCategory });
            sessionStorage.clear();
        }
     }

    showPassword = () => {
        if(this.state.showPasswordText === "See"){
           this.setState({ showPasswordText: 'Hide', showPassword1: true }) 
        }else{
           this.setState({ showPasswordText: 'See', showPassword1: false }) 
        }
    }

    showPassword1 = () => {
        if(this.state.showPasswordText1 === "See"){
           this.setState({ showPasswordText1: 'Hide', showPassword2: true }) 
        }else{
           this.setState({ showPasswordText1: 'See', showPassword2: false }) 
        }
    }

    handleRegister = async (e) => {
        e.preventDefault();
        this.setState({ display: "flex" })
        document.querySelector('#buttonsa').style.disable = true;

        const payload = {
            fullName: document.querySelector('#fullName').value,
            category: this.state.userCategory,
            email: document.querySelector('#email').value,
            password: document.querySelector('#password').value,
            confirmPassword: document.querySelector('#confirmPassword').value
        }

        if(payload.password != payload.confirmPassword){
            toast.error("Password is not the same as confirm password");
            this.setState({ display: "none" })
        }else {
            const sendRequest = await axios.post('https://alim.com.ng/register', payload);
            if(sendRequest.data.statusCode === 200){
                toast.success(sendRequest.data.statusMessage);
                setTimeout(() => {


                    toast.success("Please Kindly check your email inbox to continue")
                }, 5000)

                document.querySelector('#fullName').value = ""
                document.querySelector('#email').value = ""
                document.querySelector('#password').value = ""
                document.querySelector('#confirmPassword').value = ""
                this.setState({ display: "none" })
            }else {
                toast.error(sendRequest.data.statusMessage);
                this.setState({ display: "none" })
            }
        }

    }

    render() { 
        return (
            <>
            <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
            < ToastContainer />
              <div className='col-lg-12 login'>
                  <div className="row">
                    <div className="col-lg-6 side-one-login" data-aos="fade-right" data-aos-duration="1000">
                        <div className='mt-3'>
                            <Link to="/">
                                <img src={Logo} alt="" />
                            </Link>
                        </div>

                        <div className='signin-captions'>
                            <h4>Create your account</h4>
                            <p>Sign up today to experience Riz Estate platform</p>
                        </div>
                        <form autocomplete="off" onSubmit={this.handleRegister}>
                        <div className='mt-4'>
                            <label htmlFor="">Full name <span style={{ fontSize: '20px' }} className='text-danger'>*</span></label>
                            <input className='form-control' type="text" placeholder='John Doe' id='fullName' required/>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="">Email <span style={{ fontSize: '20px' }} className='text-danger'>*</span></label>
                            <input className='form-control' type="email" placeholder='Please enter your email' id='email' required/>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="">Password <span style={{ fontSize: '20px' }} className='text-danger'>*</span></label>
                                <input className='form-control' type={this.state.showPassword1 == true ? "text" : "password"} id='password' placeholder='Enter password' required/>
                            <div className='d-flex bg-primary' style={{ width: '97%' }}>
                                <span onClick={this.showPassword} className='ml-auto forget-2' style={{ marginTop: -43, cursor: 'pointer' }}> {this.state.showPasswordText} </span>
                            </div>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="">Confirm Password <span style={{ fontSize: '20px' }} className='text-danger'>*</span></label>
                                <input className='form-control' type={this.state.showPassword2 == true ? "text" : "password"} id='confirmPassword' placeholder='Confirm password' required/>
                            <div className='d-flex bg-primary' style={{ width: '97%' }}>
                                <span onClick={this.showPassword1} className='ml-auto forget-2' style={{ marginTop: -43, cursor: 'pointer' }}>{this.state.showPasswordText1}</span>
                            </div>
                        </div>

                        <div className='d-flex'>
                            <input className='mr-2' type="checkbox" required  />
                            <label className='pt-3 remeber' style={{ fontSize: '14px' }}>By creating an account you are agreeing to our <span className='terms' data-toggle="modal" data-target="#exampleModal">Terms and Conditions</span> and <span className='terms' data-toggle="modal" data-target="#exampleModal">Privacy Policy</span></label>
                        </div>


                        <div className='mt-4'>
                            <button id='buttonsa' style={{ background: '#019D9E' }} className='btn' type='submit'>Sign Up</button>
                        </div>
                        </form>

                        <div className="d-flex mt-3" >
                            
                                <p className='ml-auto forget forget-2'><Link to="/login">Login to your account?</Link></p>
                            
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-duration="1000" className="col-lg-6 bg-primary p-0 m-0 side-two-login">
                        <div className='d-flex justify-content-center align-items-center'>
                            <div style={{ width: '90%' }}>
                                <h5 className='text-right text-white'>Easy way to secure a perfect property</h5>
                                <div style={{ paddingLeft: '200px' }}>
                                    <p className='text-right text-white short-letting'>short-letting has never been easier, upload your property and book a short-let reservation in minutes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>  


                                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Terms And Conditions</h5>
        
      </div>
      <div className="modal-body">
        <div>
            <p className='font-weight-bold'>Provision of Service </p>

<p className='text-justify'> RIZ ESTATE is providing this service using legal template Therefore, while you are purchasing this service , the service is being provided by LawPàdí. As the user of this service, you are creating the Tenancy Agreement and therefore are classified as the author/creator of your own specific Agreement. LawPàdí has provided the tools for you to create your own Tenancy Agreement.</p>

<p className='font-weight-bold mt-3'>Limited Warranties </p>

<p className='text-justify'> Except as expressly provided in these Terms, to the maximum extent permitted under applicable law, this Legal Template is provided "as is" without any kind of warranty. </p>

<p className='text-justify'> You accept full responsibility for determining whether the Legal Template is suitable for any particular purpose and for protecting yourself against any possible consequential damages. </p>

<p className='text-justify'> LawPàdí does not warrant the template's quality, accuracy, timeliness, completeness, merchantability or fitness for use or purpose. LawPàdí, its agents and officers, shall not be liable for any damages whatsoever (including compensatory, special, direct, incidental, indirect, consequential, punitive or any other damages) arising out of the use or the inability to use this template. </p>

<p className='text-justify'> Except as expressly provided in these Terms or where prohibited by law, the maximum liability of NPC and LawPàdí is the amount paid to NPC by the customer. </p>



<p className='font-weight-bold mt-3'> Refunds and Complaints </p>

<p className='text-justify'> No right of a refund exists for Legal Templates which have been downloaded and accessed by the customer. </p>

<p className='text-justify'> In the event of dissatisfaction with the content or quality of this Legal Template, you will have the right to make a complaint within 90 days from the date of download of the Legal Template. All complaints will be resolved in a timely manner and the resolution outcomes include rejection of complaint with written reason for the rejection and upholding of the complaint with restitution in the form of offering a free Legal Template.</p>

        </div>
      </div>
      
    </div>
  </div>
</div>
            </>
        );
    }
}
 
export default Login;