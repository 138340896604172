import axios from 'axios';
import React, { Component } from 'react';
import LogoGreen from "../Assets/images/Riz primary logo.png"
class Receipt extends Component {
    state = { 
        reservationDetails: {},
     } 

    async componentDidMount(){
        const receiptDetails = await axios.get(`https://alim.com.ng/receipt/${this.props.match.params.id}`);
        if(receiptDetails.data.singleReceipt != null){
            console.log(receiptDetails.data.singleReceipt)
            this.setState({ reservationDetails: receiptDetails.data.singleReceipt });
        }
    }

    render() { 
        return (
            <div className='d-flex justify-content-center'>
                <div className='receipt-container'>
                    <div className='d-flex align-items-center'>
                        <img style={{ height: 40 }} src={LogoGreen} alt="" />
                        <h6 className='ml-auto' style={{ color: '#9E9E9E', fontWeight: 'normal', fontSize: 13 }}>Transaction Ref ID: {this.state.reservationDetails.refrence}</h6>
                    </div>

                    <div className='text-center mt-4'>
                        <h5>Your Transaction Receipt</h5>
                    </div>

                    <div>
                        <hr style={{ borderColor: "#019D9E" }} />
                    </div>

                    <div>
                        <h5 style={{ color: '#019D9E', fontSize: '17px' }}>Payout</h5>
                    </div>

                    <div className='receipt-round mt-3'>
                        
                        <div className='d-flex align-items-center'>
                            <h6>Payment:</h6>
                            <p className='ml-auto'>NGN {this.state.reservationDetails.amount}</p>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6>Property Name:</h6>
                            <p className='ml-auto'>{this.state.reservationDetails.propertyName}</p>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6>Check In Date:</h6>
                            <p className='ml-auto'>{this.state.reservationDetails.checkIn}</p>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6>Check Out Date:</h6>
                            <p className='ml-auto'>{this.state.reservationDetails.checkOut}</p>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6>Reservation Owner:</h6>
                            <p className='ml-auto'>{this.state.reservationDetails.guestName}</p>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6>Agent Email:</h6>
                            <p className='ml-auto'>{this.state.reservationDetails.agentEmail}</p>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6>Location Address:</h6>
                            <p className='ml-auto'>{this.state.reservationDetails.locationAddress}</p>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6>Status:</h6>
                            <p className='ml-auto'>{this.state.reservationDetails.status}</p>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <div>
                            <p style={{ fontSize: 12, color: "#9E9E9E" }} className='ml-auto'>DISCLAIMER <br />
                                The transfer was successful and the beneficiary account was credited, However, this transaction is subjected to network providers wherein transactions may be delayed due to interruption,incorrect data,delay in transmission of transaction details sent, etc.All transactions will be verified and our normal fraud checks will be applied. </p>
                        </div>
                    </div>


                    <div className='mt-5'>
                        <div>
                            <p style={{ fontSize: 12, color: "#9E9E9E" }} className="text-center">Email Us at hi@rizestate.co if you have any questions</p>
                        </div>
                        <div>
                        <hr style={{ borderColor: "#019D9E" }} />
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Receipt;