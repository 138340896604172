import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Habz from "../Assets/images/camera.png"
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import userImg from "../Assets/images/user.png"
import { BallTriangle } from  'react-loader-spinner'
import Notificationss from "../Assets/images/Solid/Status/Notification.png"

class Contact extends Component {

    state = {
        user: {},
        file: null,
        month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        date: "",
        display: "none",
        userImage: "",
        disabledBTN: false
    }

     handleImages = (e) => {
        let file = e.target.files[0];
        if(file.size/1024 <= 1024 * 2){
            this.setState({ file: file });
        }else {
            document.querySelector('#filse').value = "";
            toast.error("Maximum file size you can upload is 2MB");
        }
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/profile');  
            const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const theUser = fetchUser.data.user.dateRegistered;
            const theDate =  new Date(theUser);
            const day = theDate.getDate();
            const month = theDate.getMonth();
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            const Days = day + " " + this.state.month[month] + ", " + theDate.getFullYear();
            this.setState({ user: fetchUser.data.user, date: Days, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }

    handleLogout = () => {
        sessionStorage.clear();
        this.props.history.push("/login");
    }

    handleUpdate = async (e) => {
        e.preventDefault();
        this.setState({ display: 'flex' })
        const payload = {
            gender: document.querySelector('#gender').value === "" ? document.querySelector('#gender').placeholder : document.querySelector('#gender').value,
            DOB: document.querySelector('#DOB').value === "" ? document.querySelector('#DOB').placeholder : document.querySelector('#DOB').value,
            address: document.querySelector('#address').value === "" ? document.querySelector('#address').placeholder : document.querySelector('#address').value,
            state: document.querySelector('#state').value === "" ? document.querySelector('#state').placeholder : document.querySelector('#state').value,
            aboutme: document.querySelector('#aboutme').value === "" ? document.querySelector('#aboutme').placeholder : document.querySelector('#aboutme').value,
            bankName: document.querySelector('#bankName').value === "" ? document.querySelector('#bankName').placeholder : document.querySelector('#bankName').value,  
            bankAccountNumber: document.querySelector('#bankAccountNumber').value === "" ? document.querySelector('#bankAccountNumber').placeholder : document.querySelector('#bankAccountNumber').value,            
            email: this.state.user.email
        }

        const updateProfile = await axios.post("https://alim.com.ng/updateprofile", payload);
        this.setState({ display: 'none' })
        if(updateProfile.data.statusCode === 200){
            toast.success("User Profile Updated Successfully");
        }else {
            toast.error('Something Went Wrong')
        }
    }

    handleAllCountry = async () => {
        
    }

    handleImageSubmit = async (e) => {
        e.preventDefault();
        this.setState({ disabledBTN: true })
        this.setState({ display: 'flex' })
        let formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('email', this.state.user.email);
        const postImage = await axios.post('https://alim.com.ng/profileImage', formData);
        if(postImage.data.statusCode == 200){
            toast.success('Successfully Updated')
            setTimeout(() => {
                window.location.href = "/profile"
            },3000)
        }else {
            toast.error("Something Went Wrong")
        }
    }

    handleChangePassword = async (e) => {
        e.preventDefault();
        this.setState({ display: 'flex' })

        const payload = {
            oldPassword: document.querySelector('#oldPassword').value,
            newPassword: document.querySelector('#newPassword').value,
            confirmPassword: document.querySelector('#confirmPassword').value
        }

        if(payload.newPassword !== payload.confirmPassword){
            toast.error('New Password and Confirm Password are not the same');
            this.setState({ display: 'none' })
        }else {
            const updatePassword = await axios.post("https://alim.com.ng/updatePassword", { oldPassword: payload.oldPassword, newPassword: payload.newPassword, email: this.state.user.email });
            if(updatePassword.data.statusCode === 200){
                toast.success("Password Changed Successfully")
                this.setState({ display: 'none' })
            }else {
                toast.error(updatePassword.data.statusMessage)
                this.setState({ display: 'none' })
            }
        }
    }

    render() { 
        return (
            <>
            <ToastContainer />
            <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Affiliates
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                  
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

              <div className='wrapper-contact-form pb-4'>
                  <Link to="/dashboard">
                    <div className='d-flex align-items-center pt-4'>
                        <i className='bx iconp bx-chevron-left mr-3'></i>
                        <p>Back to Dashboard</p>
                    </div>
                  </Link>

                  <div className='mt-4'>
                      <h5>My profile</h5>
                      <p>Edit your profile details</p>
                  </div>
              </div>

              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-3" data-aos="fade-right" data-aos-duration="1000">
                        <div className='profile-card d-flex justify-content-center'>
                            <div className='text-center'>
                                { this.state.userImage == "" ? 
                                    <img style={{ height: '80px' }} src={userImg } alt="" />
                                :
                                <img style={{ height: '80px', borderRadius: '50px' }} src={ "https://alim.com.ng/images/" + this.state.userImage } alt="" />
                                }
                                <img data-toggle="modal" data-target="#exampleModal" style={{ height: '30px', cursor: 'pointer', marginTop: 50, marginLeft: -20 }} src={Habz} alt="" />
                                <p className='mt-3 profile-name text-capitalizen font-weight-bold' style={{ fontSize: 16 }}>{this.state.user.fullName}</p>
                                <p className='profile-name mt-1'><span className='font-weight-bold'>Email</span>: {this.state.user.email}</p>
                                <p className='profile-name mt-1 text-capitalize'><span className='font-weight-bold'>User type</span>: {this.state.user.category}</p>
                                <p className='profile-name mt-1'><span className='font-weight-bold'>Date joined</span>: {this.state.date}</p>
                            </div>
                        </div>
                      </div>

                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Upload Profile Picture</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form onSubmit={this.handleImageSubmit}>
            <label htmlFor="">Select Picture</label>
            <input onChange={this.handleImages} id="filse" className='form-control' type="file" accept="image/*" required />
            <div className='d-flex justify-content-center'>
                <button className='btn btn-primary mt-2' style={{ background: '#019D9E', border: '0px' }} disabled={this.state.disabledBTN}>Upload</button>
            </div>
        </form>
      </div>
    </div>
  </div>
</div>

                      <div className="col-lg-9">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '150px', padding: 10 }} >Profile details</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleUpdate}>

                                <div className="row">
                                    { this.state.user.gender !== "" ? 
                                    <div className='col-lg-6' style={{ display: 'none' }}>
                                            <label htmlFor="">Gender</label>
                                            <select className='form-controls' name="" id="gender" placeholder={this.state.user.gender}>
                                                <option value="">Please Select</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>  :
                                        <div className='col-lg-6'>
                                            <label htmlFor="">Gender</label>
                                            <select className='form-controls' name="" id="gender" placeholder={this.state.user.gender}>
                                                <option value="">Please Select</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div> 
                                    }
                                    { this.state.user.dateOfBirth !== "" ? 
                                    <div className='col-lg-6 mt-4' style={{ display: 'none' }}>
                                        <label htmlFor="">Date of birth</label>
                                        <input className='form-controls' type="date" id="DOB" placeholder={this.state.user.dateOfBirth} />
                                    </div> :
                                        <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">Date of birth</label>
                                        <input className='form-controls' type="date" id="DOB" placeholder={this.state.user.dateOfBirth} />
                                    </div>
                                    }
                                    
                                    
                                    <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">Address</label>
                                        <input className='form-controls' placeholder={this.state.user.address} type="text" id="address" />
                                    </div>

                                    <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">State</label>
                                        <input className='form-controls' placeholder={this.state.user.state} type="text" id="state" />
                                    </div>

                                    <div className='col-lg-12 mt-4'>
                                        <label htmlFor="">About me</label>
                                        <input className='form-controls' placeholder={this.state.user.aboutMe} id="aboutme" type="text" />
                                    </div>


                                    <div className='mt-5 ml-2 col-lg-12'>
                                        <h6 className='bank-head'>Bank details</h6>
                                        <p className='p-0' style={{ fontSize: 12, marginTop: -6 }}>Please make sure your profile name match your account name</p>
                                    </div>  

                                    
                                    <div className='col-lg-6 mt-5'>
                                        <label htmlFor="">Bank name</label>
                                        <input className='form-controls' placeholder={this.state.user.bankName} id="bankName" type="text" />
                                    </div>

                                    <div className='col-lg-6 mt-5'>
                                        <label htmlFor="">Bank account number</label>
                                        <input className='form-controls' placeholder={this.state.user.bankAccountNumber} id="bankAccountNumber" type="number" />
                                    </div>

                                    <div className='col-lg-12 d-flex mt-4 justify-content-center mb-4'>
                                        <button type='submit'>Update profile</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>



              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-3">
                    
                      </div>
                      <div className="col-lg-9">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '200px', padding: 10 }} >Manage password</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleChangePassword}>

                                <div className="row"> 

                                    <div className='col-lg-6'>
                                        <label htmlFor="">Old password</label>
                                        <input id="oldPassword" className='form-controls' placeholder='Enter old password' type="password" required />
                                    </div>

                                    <div className='col-lg-6'>
                                        <label htmlFor="">New password</label>
                                        <input id="newPassword" className='form-controls' placeholder='Enter new password' type="password" required />
                                    </div>
                                    <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">Confirm new password</label>
                                        <input id="confirmPassword" className='form-controls' placeholder='Enter new password' type="password" required />
                                    </div>

                                    <div className='col-lg-12 d-flex mt-4 justify-content-center mb-4'>
                                        <button type='submit'>Update Password</button>
                                    </div>

                                </div>
                                </form>
                                    
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
            </>
        );
    }
}
 
export default Contact;