import React, { Component } from 'react';
import Image1 from "../../Assets/images/unsplash_yETqkLnhsUI.png"
import confirmImage from "../../Assets/images/Rectangle 292.png"

class ListingImage extends Component {
    state = { 
        images: this.props.images,
        propertyId: this.props.propertyId,
        perCampaign: [],
        singleImage: "",
        singleImage2: "",
        singleImage3: "",
        singleImage4: "",
        singleImage5: ""
     } 

     async componentDidMount(){
         console.log(this.state.propertyId)
         const filterData = await this.state.images.filter(a => a.propertyId == this.state.propertyId)
         
         this.setState({ perCampaign: filterData, singleImage: JSON.parse(filterData[0].image).filename, singleImage2: JSON.parse(filterData[1].image).filename, singleImage3: JSON.parse(filterData[2].image).filename, singleImage4: JSON.parse(filterData[3].image).filename, singleImage5: JSON.parse(filterData[4].image).filename });
     }
    render() { 
        return (
            <>
                <div className="col-lg-6">
                                        <img className='mb-4' style={{ height: '500px', borderRadius: 10, width: '100%' }} src={"https://alim.com.ng/images/" + this.state.singleImage} alt="" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <img className='mb-4' style={{ height: '230px', borderRadius: 10, width: '100%' }} src={"https://alim.com.ng/images/" + this.state.singleImage2} alt="" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <img className='mb-4' style={{ height: '230px', borderRadius: 10, width: '100%' }} src={"https://alim.com.ng/images/" + this.state.singleImage3} alt="" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <img className='mb-4' style={{ height: '230px', borderRadius: 10, width: '100%' }} src={"https://alim.com.ng/images/" + this.state.singleImage4} alt="" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <img className='mb-4' style={{ height: '230px', borderRadius: 10, width: '100%' }} src={"https://alim.com.ng/images/" + this.state.singleImage5} alt="" />
                                                </div>
                                            </div>
                                        </div>
            </>
        );
    }
}
 
export default ListingImage;