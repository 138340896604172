import React, { useEffect, useRef, useState } from "react";
import Login from "./Components/Auth/login"
import Index from "./Components/index"
import Register from "./Components/Auth/register"
import Reset from "./Components/Auth/reset"
import ForgotPassword from "./Components/Auth/forgot"
import ShortLet from "./Components/shortlet"
import Rent from "./Components/rent"
import Sales from "./Components/sales"
import Search from "./Components/search"
import NotFound from "./Components/notFound"
import Confirm from "./Components/confirm"
import Property from "./Components/propertyDetails"
import Contact from "./Components/contact"
import About from "./Components/about"
import Team from "./Components/team"
import Agent from "./Components/agent";
import Affiliates from "./Components/affiliates"
import FAQ from "./Components/supportPage"
import contactForm from "./Components/contactForm"
import Dashboard from "./Components/dashboard"
import Notifications from "./Components/notifications"
import Saved from "./Components/saved"
import MyListing from "./Components/listing"
import PendingProperty from "./Components/pendingProperty"
import RejectedProperty from "./Components/rejectedProperty"
import reservedProperty from "./Components/reservedProperty"
import Profile from "./Components/profile"
import Statistics from "./Components/statistics"
import Category from "./Components/category";
import ActivateMail from "./Components/activate";
import addProperty from "./Components/addProperty";
import addPropertyImage from "./Components/addPropertyImage";
import AllBooking from "./Components/allBookings";
import AllBookings from "./Components/allBooking";
import AllPayment from "./Components/payment";
import AdminPay from "./Components/allPayment";
import AllAgent from "./Components/allAgent";
import AllGuest from "./Components/allguest";
import AllAffiliates from "./Components/allAffiliates";
import AllProperty from "./Components/allProperty";
import ClientBooking from "./Components/clientBooking";
import ScrollToTop from "./scrollToTop.js";
import PropertyDetail from "./Components/propertyDetail";
import Receipt from "./Components/receipt";
import {Route, Switch} from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css"

const App = () => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return ( 
    <React.Fragment> 
      <ScrollToTop />
      <Switch>
        <Route path="/addPropertyImage/:id" component={addPropertyImage} />
        <Route path="/verifymail/:id" component={ActivateMail} />
        <Route path="/category" component={Category} />
        <Route path="/allguest" component={AllGuest} />
        <Route path="/allProperty" component={AllProperty} />
        <Route path="/allBooking" component={AllBookings} />
        <Route path="/clientBooking" component={ClientBooking} />
        <Route path="/allaffiliates" component={AllAffiliates} />
        <Route path="/allAgent" component={AllAgent} />
        <Route path="/statistics" component={Statistics} />
        <Route path="/payment" component={AllPayment} />
        <Route path="/allpayment" component={AdminPay} />
        <Route path="/allBookings" component={AllBooking} />
        <Route path="/profile" component={Profile} />
        <Route path="/mylisting" component={MyListing} />
        <Route path="/pendingProperty" component={PendingProperty} />
        <Route path="/rejectedProperty" component={RejectedProperty} />
        <Route path="/reservedProperty" component={reservedProperty} />
        <Route path="/savedproperty" component={Saved} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/contactform" component={contactForm} />
        <Route path="/support" component={FAQ} />
        <Route path="/affiliates" component={Affiliates} />
        <Route path="/agent" component={Agent} />
        <Route path="/team" component={Team} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/property/:id" component={Property} />
        <Route path="/propertys/:id" component={PropertyDetail} />
        <Route path="/confirm" component={Confirm} />
        <Route path="/notFound" component={NotFound} />
        <Route path="/search" component={Search} />
        <Route path="/sales" component={Sales} />
        <Route path="/rent" component={Rent} />
        <Route path="/shortlet" component={ShortLet} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/reset/:id" component={Reset} />
        <Route path="/register" component={Register} />
        <Route path="/addProperty" component={addProperty} />
        <Route path="/login" component={Login} />
        <Route path="/contactus" component={Contact} />
        <Route path="/receipt/:id" component={Receipt} />
        <Route path="/" component={Index} />
      </Switch>
    </React.Fragment>
   );
}
 
export default App;