import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Notifications from "../Assets/images/Icon.png"
import Empty from "../Assets/images/empty.svg"

import Notificationss from "../Assets/images/Solid/Status/Notification.png"
import userImg from "../Assets/images/user.png"

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';


class Contact extends Component {
    state = { 
        allNotifications: [],
        month: ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        allImage: [],
        userImage: ""
    } 

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/notifications');  
          const fetchAllListing = await axios.get(`https://alim.com.ng/getAllNotifications/${user.email}`);
          this.setState({ allNotifications: fetchAllListing.data.allNotifications })
          console.log(fetchAllListing);

          const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }
    render() { 
        return (
            <>
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
               


              <div className='wrapper-contact-form pb-4'>
                  <Link to="/dashboard">
                    <div className='d-flex align-items-center pt-4'>
                      <i className='bx iconp bx-chevron-left mr-3'></i>
                      <p>Back to Dashboard</p>
                    </div>
                  </Link>

                  <div className='mt-4'>
                      <h5>Notification</h5>
                      <p>Explore all dashboard features</p>
                  </div>
              </div>


                { this.state.allNotifications.length == 0 ? 
                <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <div>
                        <div>
                            <img style={{ height: 300 }} src={Empty} alt="" />
                        </div>
                        <div className='mt-3'>
                            <p style={{ fontSize: 18, color: 'gray' }} className='text-center'>No Notifications Found at this time</p>
                        </div>
                    </div>
                </div>
                :
                <div className='mt-5 notifications mb-4'>
                    { this.state.allNotifications.map(notificationNew => 
                    <>
                    <div className='notifications-card mb-4 d-flex align-items-center'>
                        <div className="p-3 pr-4" style={{ borderRight: '0.2px solid #000000' }}>
                            <img src={Notifications} className="notis-icon" alt="" />
                        </div>
                        <div className='noti-content'>
                            <p className='noti-head'>{notificationNew.title}</p>
                            <p className='mt-3 noti-description'> {notificationNew.description}</p>
                            <p className='noti-time'>{new Date(notificationNew.date).getDate()} {this.state.month[new Date(notificationNew.date).getMonth()]} {new Date(notificationNew.date).getFullYear()}</p>
                        </div>
                        <div className='ml-auto'>
                            <button data-toggle="modal" data-target={"#exampleModal" + notificationNew._id}>View</button>
                        </div>
                    </div>
<div class="modal fade" id={"exampleModal" + notificationNew._id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{notificationNew.title}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {notificationNew.description}
      </div>
    </div>
  </div>
</div>
                </>

) }
              </div>
}
            </>
        );
    }
}
 
export default Contact;