import React, { Component } from 'react';
import Logo from "../Assets/images/Riz secondary logo.png"
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Twitter from "../Assets/images/Twitter - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import Back from "../Assets/images/Outline/Interface/Icon.png"
import confirmImage from "../Assets/images/Rectangle 292.png"
import Location from "../Assets/images/Outline/Navigation/Icon.png"
import Star from "../Assets/images/Star 1.png"

import { Link } from 'react-router-dom';

class Rent extends Component {
    state = { 
        activeSearch: "shortlet"    
    } 

    handlSearchChange = async(e) => {
        this.setState({activeSearch: e.target.id})
    }

    render() { 
        return (
            <div>
                <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agents'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='ml-3'>
                                <Link to='/register' style={{ background: '#019D9E', borderRadius: '10px' }}>
                                    <button style={{ background: '#019D9E', borderRadius: '10px' }} className='btn text-white'>SIGN UP</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    

                    
                    
                

                </div>
                    

                    <div className='confirm-wrapper'>
                        <div className='d-flex align-items-center'>
                            <img src={Back} style={{ height: '20px' }} alt="" />
                            <p className='pay-header ml-4'>Confirm and pay</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className='mt-5'>
                                    <img className='mb-4' style={{ height: '200px' }} src={confirmImage} alt="" />
                                    <h5 className='pay-header'>2 bedroom flat/Apartment</h5>
                                    <div className='d-flex align-items-center'>
                                        <img className='mr-4' style={{ height: '20px' }} src={Location} alt="" />
                                        <p className='location-pay'>Ikate, Lekki, Lagos</p>
                                    </div>
                                    <p className='pay-description'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate, this apartment was designed with attention.</p>
                                    <p className='mt-3 d-flex align-items-center'><span className='rating mr-1'>4.5</span> <img className='mr-1' style={{ height: '14px' }} src={Star} alt="" />  <span className='rating-number'> (6 reviews)</span></p>
                                    <hr style={{ marginTop: '30px' }} />

                                    <h5 className='policies'>Booking policies</h5>
                                    <p className='cancellation'>Free cancellation before Sep 4. Cancel before check-in on Sep 5 for a partial refund. Learn more</p>

                                    <button className='mt-5 btn paynow'>Confirm and pay</button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className='side-2-pay'>
                                    <div className='side-wrappers'>
                                        <h5 className='d-flex align-items-center'><span className='mr-2'>₦50,000</span> <p> Per day</p></h5>
                                        <div className='mt-4 box-pay'>
                                            <div className='box-pays'>

                                            <div className='ils'>
                                                <h6>CHECK IN</h6>
                                                <p>11/1/2022</p>
                                            </div>
                                            <div>
                                                <h6>CHECK OUT</h6>
                                                <p>11/1/2022</p>
                                            </div>
                                            </div>

                                            <div className='p-3 mt-4'>
                                                <h6 className='gue-check'>GUESTS</h6>
                                                <p className='mt-2 gue-p'>1 Guest</p>
                                            </div>

                                        </div>
                                            <div className='payment-dec'>
                                                <h5 className='mb-4'>Price details</h5>

                                                <div className='d-flex mt-1'>
                                                    <p>50,000 x 2 days</p>
                                                    <p className='ml-auto'>₦100,000</p>
                                                </div>

                                                <div className='d-flex mt-2'>
                                                    <p>Service fee(5%)</p>
                                                    <p className='ml-auto'>₦1,000</p>
                                                </div>

                                                <div className='d-flex mt-2'>
                                                    <p>VAT</p>
                                                    <p className='ml-auto'>₦1,000</p>
                                                </div>

                                                <div className='mt-5 pr-terms'>
                                                    <p>Payment terms & conditions</p>
                                                </div>

                                                <hr className='mt-4' />

                                                <div className='d-flex mt-4'>
                                                    <p>Total</p>
                                                    <p className='ml-auto desc-price'>₦102,000</p>
                                                </div>
                                                
                                            </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    
                
                <footer>
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <img src={Facebook} alt="" />
                        <img src={Twitter} alt="" />
                        <img src={Instagram} alt="" />
                        <img src={Linkedin} alt="" />


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Features</li>
                        <li className='mt-3'>Partners</li>
                        <li className='mt-3'>Pricing </li>
                        <li className='mt-3'>Product</li>
                        <li className='mt-3'>Support</li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>About Us</li>
                        <li className='mt-3'>Agents</li>
                        <li className='mt-3'>Blog </li>
                        <li className='mt-3'>Media</li>
                        <li className='mt-3'>Contact Us</li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                        
                    </ul>

                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                        <input  placeholder='Email Address' type="text" />
                        <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                            <img style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                        </div>
                    </div>
                </div> 
            </footer>
            </div>
        );
    }
}
 
export default Rent;