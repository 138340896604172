import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import axios from 'axios';
import Empty from "../Assets/images/empty.svg"
import { toast, ToastContainer } from 'react-toastify';

import Notificationss from "../Assets/images/Solid/Status/Notification.png"
import userImg from "../Assets/images/user.png"

class Contact extends Component {
    state = { 
        allBookings: [],
        month: ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        allImage: []
    } 

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          const fetchAllListing = await axios.get(`https://alim.com.ng/getAllBookings/${user.email}`);
          this.setState({ allBookings: fetchAllListing.data.allBookings })

          const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }


    handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }

    
    render() { 
        return (
            <>
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
               


              <div className='wrapper-contact-form pb-4'>
                  <Link to="/dashboard">
                    <div className='d-flex align-items-center pt-4'>
                      <i className='bx iconp bx-chevron-left mr-3'></i>
                      <p>Back to Dashboard</p>
                    </div>
                  </Link>

                  <div className='mt-4'>
                      <h5>All bookings</h5>
                      <p>View all booking  details</p>
                  </div>
              </div>

              { this.state.allBookings.length == 0 ? 
                <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <div>
                        <div>
                            <img style={{ height: 300 }} src={Empty} alt="" />
                        </div>
                        <div className='mt-3'>
                            <p style={{ fontSize: 18, color: 'gray' }} className='text-center'>No Booking Found at this time</p>
                        </div>
                    </div>
                </div>
                :

            <div className='table-head table-responsive d-flex justify-content-center'>
              <table class="table">
                <thead style={{ background: '#019D9E' }}>
                  <tr>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Property name</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Location</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Amount</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Guest name</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Email</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Phone Number</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Check In</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Check Out</th>
                    <th style={{ color: "white", fontSize: 13 }} scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                    { this.state.allBookings.map(bookings => 
                    <tr>
                        <td style={{ fontSize: 13 }} className='text-capitalize'>{ bookings.propertyName }</td>
                        <td style={{ fontSize: 13 }} className='text-capitalize'>{ bookings.locationAddress }</td>
                        <td style={{ fontSize: 13 }}>{ bookings.amount }</td>
                        <td style={{ fontSize: 13 }} className='text-capitalize'>{ bookings.guestName }</td>
                        <td style={{ fontSize: 13 }}>{ bookings.email }</td>
                        <td style={{ fontSize: 13 }}>{ bookings.phoneNumber }</td>
                        <td style={{ fontSize: 13 }}>{ bookings.checkIn }</td>
                        <td style={{ fontSize: 13 }}>{ bookings.checkOut }</td>
                        <td style={{ fontSize: 13 }} className='text-capitalize'>{ bookings.status }</td>
                    </tr>
                    )}
                </tbody>
              </table>
            </div>
            }

            </>
        );
    }
}
 
export default Contact;