import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"

import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Twitter from "../Assets/images/Twitter - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import Landing5 from "../Assets/images/Rectangle 281.png"

import Call from "../Assets/images/Frame 203.png"
import Email from "../Assets/images/Frame 2033.png"
import Locate from "../Assets/images/Frame 2035.png"

class Contact extends Component {
    state = {  } 
    render() { 
        return (
            <>
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agents'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='ml-3'>
                                <Link to='/register' style={{ background: '#019D9E', borderRadius: '10px' }}>
                                    <button style={{ background: '#019D9E', borderRadius: '10px' }} className='btn text-white'>SIGN UP</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
               


              <div className='wrapper-contact-form pb-4'>
                  <div className='d-flex align-items-center pt-4'>
                      <i className='bx iconp bx-chevron-left mr-3'></i>
                      <p>Back to Homepage</p>
                  </div>

                  <div className='mt-4'>
                      <h5>Contact</h5>
                      <p>Manage and send message to the host services</p>
                  </div>
              </div>

              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-3">
                        <div className='locations'>
                            <img style={{ height: '50px' }} className="mr-3" src={Call} alt="" />
                            <div className='content-forms'>
                                <h6>Phone number</h6>
                                <p>+234 80 30737 582</p>
                            </div>
                        </div>

                        <div className='locations mt-4'>
                            <img style={{ height: '50px' }} className="mr-3" src={Email} alt="" />
                            <div className='content-forms'>
                                <h6>Email address</h6>
                                <p>Rizestateproperty@gmail.com</p>
                            </div>
                        </div>

                        <div className='locations mt-4'>
                            <img style={{ height: '50px' }} className="mr-3" src={Locate} alt="" />
                            <div className='content-forms'>
                                <h6>Office address</h6>
                                <p>Suite 14, royal street, off benson avenue, Lekki,Lagos state</p>
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-9">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '150px', padding: 10 }} >Send message</p>
                            <div className='the-form'>
                                <div className="row">
                                    <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">Your name</label>
                                        <input className='form-controls' type="text" placeholder='Enter your name' />
                                    </div>
                                    <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">Your email</label>
                                        <input className='form-controls' placeholder='Enter email' type="text" />
                                    </div>

                                    <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">Phone number</label>
                                        <input className='form-controls' placeholder='Enter phone number' type="text" />
                                    </div>

                                    <div className='col-lg-6 mt-4'>
                                        <label htmlFor="">Subject</label>
                                        <input className='form-controls' placeholder='Add subject' type="text" />
                                    </div>

                                    <div className='col-lg-12 mt-4'>
                                        <label htmlFor="">Message</label>
                                        <input className='form-controls' placeholder='Enter Message' type="text" />
                                    </div>

                                </div>
                                    <div className='d-flex justify-content-center mb-4'>
                                        <button>Send message</button>
                                    </div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>

                <footer>
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <img src={Facebook} alt="" />
                        <img src={Twitter} alt="" />
                        <img src={Instagram} alt="" />
                        <img src={Linkedin} alt="" />


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Features</li>
                        <li className='mt-3'>Partners</li>
                        <li className='mt-3'>Pricing </li>
                        <li className='mt-3'>Product</li>
                        <li className='mt-3'>Support</li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>About Us</li>
                        <li className='mt-3'>Agents</li>
                        <li className='mt-3'>Blog </li>
                        <li className='mt-3'>Media</li>
                        <li className='mt-3'>Contact Us</li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                        
                    </ul>

                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                        <input  placeholder='Email Address' type="text" />
                        <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                            <img style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                        </div>
                    </div>
                </div> 
            </footer>
            </>
        );
    }
}
 
export default Contact;