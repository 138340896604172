import React, { Component } from 'react';
import Logo from "../Assets/images/Riz secondary logo.png"
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Location from "../Assets/images/Outline/Navigation/Icon.png"
import Arrow from "../Assets/images/Arrow 4.png"
import Calendar from "../Assets/images/calendar_1_.png"
import Premium from "../Assets/images/Frame 146.png"
import Vector from "../Assets/images/Vector.png"
import Vector1 from "../Assets/images/Vector2.png"
import Vector2 from "../Assets/images/Vector3.png"
import Vector3 from "../Assets/images/Vector4.png"
import { BallTriangle } from  'react-loader-spinner'
import Landing5 from "../Assets/images/Rectangle 281.png"
import Hand from "../Assets/images/Frame.png"
import Affiliate from "../Assets/images/OBJECTS.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import currencyFormatter from "currency-formatter";

import ListingImage from './Common/shortLetimage';


class Index extends Component {
    state = { 
        activeSearch: "shortlet",
        allProperty: [],
        allImage: [],
        month: ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],    
        display: "none"
    } 

    async componentDidMount(){
        const getData = await axios.get('https://alim.com.ng/getShortletss');
        if(getData.data.statusCode === 200){
            this.setState({ allProperty: getData.data.allProperty });
            const allImage = getData.data.updateImage;
            this.setState({ allImage });
        }
    }

    handlSearchChange = async(e) => {
        this.setState({activeSearch: e.target.id})
    }

    handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }

        this.setState({ display: "flex" })
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
            this.setState({ display: "none" })
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
                this.setState({ display: "none" })
            }else {
                toast.error(newsletter.data.statusMessage);
                this.setState({ display: "none" })
            }
        }else {
            toast.error("Please input a valid email address");
            this.setState({ display: "none" })
        }
    }

    
    render() { 
        return (
            <>
            <ToastContainer />
           <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
            <div style={{ background: '#fafafa' }}>
                <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/login">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Login
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>
                <div className="landing">
                    <div className='desktop-nav'>


                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={Logo} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'white' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'white' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'white' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'white' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'white' }} to='/contact'>CONTACT US</Link></li>
                                <li><Link style={{ color: 'white' }} to='/login'>Login</Link></li>

                            </ul>
                            <div className='ml-3'>
                                <Link to='/register'>
                                    <button className='btn'>SIGN UP</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    

                    <div className="landing-description pt-4">
                        <h5>The Best place to find the Perfect Short-let services</h5>
                    </div>
                    <div className='landing-caption'>
                        <p>We provide a complete service for the shortlet, sale, purchase or rental of real estate.</p>
                    </div>
                    
                    <div className="search-landing pb-4">
                        <div className='search-menu'>
                            <div id='shortlet' onClick={this.handlSearchChange} style={{ cursor: 'pointer' }} className={ this.state.activeSearch === "shortlet" ? "active first-card" : "first-card"}>
                                <p id='shortlet'>Shortlet</p>
                            </div>
                            <div id='rent' onClick={this.handlSearchChange} style={{ cursor: 'pointer' }} className={ this.state.activeSearch === "rent" ? "active" : ""}>
                                <p id='rent'>Rent</p>
                            </div>
                            <div id='buy' onClick={this.handlSearchChange} style={{ cursor: 'pointer' }} className={ this.state.activeSearch === "buy" ? "active last-card" : "last-card"}>
                                <p id='buy'>Buy</p>
                            </div>
                        </div>
                        <div className="search-box">
                            <div className='searchbox'>

                            
                                <div className='search-bar'>
                                    <input type="text" className='mt-2' placeholder='Enter Location' />
                                </div>

                                <div className='date-selection d-flex ml-3'>
                                    { this.state.activeSearch === "shortlet" ? 
                                    <div>
                                        <p className='ml-2' htmlFor="">Check in</p>
                                        <input className='search-date mt-2' placeholder="Date" type="date" onfocus="(this.type='date')" />
                                    </div>
                                    : "" }

                                
                                    { this.state.activeSearch === "shortlet" ? 
                                    <div className='ml-4'>
                                        <p className='ml-2' htmlFor="">Check Out</p>
                                    
                                        <input className='search-date mt-2' placeholder="Date" type="date" onfocus="(this.type='date')" />
                                    </div>
                                    : "" }

                                    { this.state.activeSearch === "rent" || this.state.activeSearch === "buy" ? 
                                    <div>
                                        <p className='ml-2' htmlFor="">Property ref number</p>
                                        <select className='form-control mt-2 search-date search-date2' name="" id="">
                                            <option value="">Select type</option>
                                        </select>
                                    </div>
                                    : "" }

                                    { this.state.activeSearch === "rent" || this.state.activeSearch === "buy" ? 
                                    <div className='ml-4'>
                                        <p className='ml-2' htmlFor="">Number of bedroom </p>
                                        <select className='form-control mt-2 search-date search-date2' name="" id="">
                                            <option value="">Select type</option>
                                        </select>
                                    </div>
                                    : "" }
                                </div>

                            
                            </div>
                            <div className='searchbox align-items-center'>
                                <div className='mt-2'>
                                    <p className='ml-2 protype' htmlFor="">Property type</p>
                                    <select className='form-control mt-2 search-date search-date2' name="" id="">
                                        <option value="">Select type</option>
                                    </select>
                                </div>

                                <div className='mt-2 rangeis'>
                                    <p className='ml-2 text-center protype' htmlFor="">Set price range</p>
                                    
                                    <div class="slider mt-2">
                                        <div class="progress"></div>
                                    </div>
                                    <div class="range-input">
                                      <input type="range" class="range-min" min="0" max="10000" value="2500" step="100" />
                                      <input type="range" class="range-max" min="0" max="10000" value="7500" step="100" />
                                    </div>

                                </div>

                                <div className='mt-2 d-flex align-items-center btn-search'>
                                    <button>Search</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>
                
                <div className="landing-2">
                    <div className='d-flex justify-content-center'>
                        <div className="landing-2-header d-flex">
                            <div className='text-center landing-2-active pt-4 pb-3'>
                                <Link to="/shortlet">
                                    <h5>Shortlets</h5>
                                </Link>
                            </div>
                            <div className='text-center pt-4 pb-3'>
                                <Link style={{ textDecoration: 'none', color: 'black' }} to="/rent">
                                    <h5>Rents</h5>
                                </Link>
                            </div>
                            <div className='text-center pt-4 pb-3'>
                                <Link style={{ textDecoration: 'none', color: 'black' }} to="/sales">
                                    <h5>Sale</h5>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
                

 

                <div className="landing-3">
                    <div className="col-lg-12">
                        <div className="row align-items-center">
                            <div className="col-lg-4 mb-5">
                                <div>
                                    <h5>Latest Listed Shortlets</h5>
                                    <p className='landing-captions-3 mt-3'>We provide a complete service for the shortlet, sale, purchase or rental of real estate.</p>
                                </div>
                            </div>
                            { this.state.allProperty.map(property => 
                            <div className="col-lg-4 mb-5">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <ListingImage images={this.state.allImage} propertyId={property._id} />
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        

                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name' style={{ fontSize: 18 }}>{property.bedroom} bedroom flat / {property.subTypeProperty} apartment</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} className="mr-2" src={Location} alt="" /><u className='text-capitalize'>{property.location}</u></p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>{property.description}</p>
                                                </div>

                                                    <Link to={`/propertys/${property._id}`}>
                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                        <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>
                                                    </Link>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  {new Date(property.dateRegistered).getDate()} {this.state.month[new Date(property.dateRegistered).getMonth()]} {new Date(property.dateRegistered).getFullYear()}</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 style={{ fontSize: 20 }} className='mr-1'>{currencyFormatter.format(property.price, { code: 'NGN' })}</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>{property.bedroom} Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>{property.toilet} Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>{property.bathroom} Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            )}

                            
                        </div>
                    </div>

                    <div className='d-flex mt-5 explore-more justify-content-center'>
                        <Link to="/shortlet">
                            <button>EXPLORE MORE</button>
                        </Link>
                    </div>
                    </div>
                    
                    
                    <div className='landing-4 d-flex justify-content-center align-items-center'>
                        <div className='land-4 text-center'>
                            <h4>Discover our services</h4>
                            <p className='mt-3'>RIZ Estate offers a wide range of specialist services from financial and investment advice to valuation, planning and property management.</p>
                        </div>
                    
                        
                    </div>
                    <div className="land-4-2 d-flex justify-content-center">
                        <div className="land-4-3">
                            <div className="col-lg-12">
                                <div className="d-flex">
                                    <div className="col-sm-4 col-lg-4 ">
                                        <div className='text-center'>
                                            <h3>18,036</h3>
                                            <p>AGENTS & DEVELOPERS</p>
                                        </div>
                                    </div>
                    
                                    <div className="col-sm-4 col-lg-4">
                                        <div className='text-center'>
                                            <h3>97,930</h3>
                                            <p>PROPERTY LISTINGS</p>
                                        </div>
                                    </div>
                    
                                    <div className=" col-sm-4 col-lg-4">
                                        <div className='text-center'>
                                            <h3>291</h3>
                                            <p>AREAS COVERED</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '-30px' }} className='d-flex explore-more justify-content-center'>
                        <Link to="/about">
                            <button>EXPLORE SERVICES</button>
                        </Link>
                    </div>
                   <div className='landing-5 d-flex justify-content-center'>
                        <div className="landing-5-10">
                    
                    
                        <div className='searchbox'>
                    
                            <div>
                                <div className='land-5-1'>
                                    <img id='imgs2' className='mt-4 ml-5 check-img' src={Landing5} alt="" />
                                </div>
                                
                            </div>
                            
                            <div className='why-choose'>
                                <div className='text-center'>
                                    <h5>Why choose our services</h5>
                                </div>
                    
                                <div className='d-flex align-items-center mt-5'>
                                    <img style={{ height: '50px' }} src={Hand} alt="" />
                                    <div className='ml-4'>
                                        <h6>Discount Commission</h6>
                                        <p>We provide a complete service for the shortlet, sale, purchase or rental of real estate.</p>
                                    </div>
                                </div>
                    
                                <div className='d-flex align-items-center mt-5'>
                                    <img style={{ height: '50px' }} src={Hand} alt="" />
                                    <div className='ml-4'>
                                        <h6>Property Insurrance</h6>
                                        <p>We provide a complete service for the shortlet, sale, purchase or rental of real estate.</p>
                                    </div>
                                </div>
                    
                                <div className='d-flex align-items-center mt-5'>
                                    <img style={{ height: '50px' }} src={Hand} alt="" />
                                    <div className='ml-4'>
                                        <h6>Tax Advantage</h6>
                                        <p>We provide a complete service for the shortlet, sale, purchase or rental of real estate.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> 

                    <div className='landing-6 mb-5'>
                        <div className='landing-5-10'>
                            <div className='land-6'>
                                <div className='side-land-6-1'>
                                    <h5>Try out out Affiliate program</h5>
                                    <p>We provide shared economy model where everyoneround the world can leverage and partner with us. Our affiliate program (AP) is aimed at finding a cure for unemployment in Nigeria on a simple premises whether you are employed, unemployed, business owner, agent, marketer, student etc.</p>
                                    <Link to="/register">
                                        <button className='mt-4'>SIGN UP TODAY</button>
                                    </Link>
                                </div>
                                <div className='side-land-6-2'>
                                    <img className='ml-3' id='sidelandimg' src={Affiliate} alt="" />
                                </div>
                            </div>
                        </div>
                    </div> 
    












<div className='landing-5'>
    <div className="landing-5-10">


    <div className='landing-5-flex'>

        <div className='why-chooses mt-2'>
            <div className='mt-3 mb-3'>
                <h5>
                    Market your property
                </h5>

                <p>Get specialist advice from local experts across all types of residential, commercial and rural property.</p>
                <Link to="/register">
                    <button className='mt-4'>SIGN UP TODAY</button>
                </Link>
            </div>
        </div>

        <div id='div-imgs'>
            <img id='imgs' src={Landing5} alt="" />
        </div>
    </div>
    </div>
</div>


               <footer data-aos="fade-up" data-aos-duration="1000">
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>Riz Estate © 2023. All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
            </div>
             </>
        );
    }
}
 
export default Index;