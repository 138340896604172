import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"
import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import AddProperty from "../Assets/images/icons8-add-property-50 1.png"
import MyListing from "../Assets/images/clarity_list-line.png"
import Notifications from "../Assets/images/Icon.png"
import SavedProperty from "../Assets/images/allbooking.png"
import SavedPropertys from "../Assets/images/Iconf.png"
import Profile from "../Assets/images/Outline/Communication/Icon.png"
import LogOut from "../Assets/images/icons8-log-out-64 1.png"
import Call from "../Assets/images/contact us/Outline/Communication/Icon.png"
import Payment from "../Assets/images/carbon_money.png"
import userImg from "../Assets/images/user.png"
import Notificationss from "../Assets/images/Solid/Status/Notification.png"
import AllAgent from "../Assets/images/Vector22.png"
import AllAffiliates from "../Assets/images/akar-icons_network.png"
import AllClient from "../Assets/images/carbon_user-avatar.png"

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

class Contact extends Component {
    state = { 
        userDetails: {},
        userImage: "",
     } 

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        this.setState({ userDetails: user })
        console.log(user);
        if(user){
          this.props.history.push('/dashboard');  
            const payload = {
                email: user.email
            }
          const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const theUser = fetchUser.data.user.dateRegistered;
            const theDate =  new Date(theUser);
            const day = theDate.getDate();
            const month = theDate.getMonth();
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }

    handleLogout = () => {
        sessionStorage.clear();
        this.props.history.push("/login");
    }

     handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }

    render() { 
        return (
            <>
            <ToastContainer />
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Affiliates
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                  
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
               


              <div className='wrapper-contact-form pb-4'>
                  <Link to="/">

                  <div className='d-flex align-items-center pt-4'>
                      <i className='bx iconp bx-chevron-left mr-3'></i>
                      <p>Back to Homepage</p>
                  </div>
                  </Link>

                  <div className='mt-4'>
                      <h5>Dashboard</h5>
                      
                        <p>Explore all dashboard features</p>
                  </div>
              </div>
              
                <div className="dashboard-wrapper mt-5 mb-5">
                    <div className="col-lg-12">
                    <div className="row">

                    { this.state.userDetails.category == "agent" ? 
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/addProperty" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={AddProperty} alt="" />
                                    <h5 className='mt-3'>Add property</h5>
                                    <p>Add property listing</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""

                    }
                    { this.state.userDetails.category == "agent" ?
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/mylisting" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={MyListing} alt="" />
                                    <h5 className='mt-3'>My listing</h5>
                                    <p>View all property listing</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""
                    }

                    { this.state.userDetails.category != "superadmin" ?
                    <>
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/notifications" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={Notifications} alt="" />
                                    <h5 className='mt-3'>Notifications</h5>
                                    <p>View all notifications about property</p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    </>: ""
                    }
                    { this.state.userDetails.category == "client" ?
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/clientBooking" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={SavedProperty} alt="" />
                                    <h5 className='mt-3'>My bookings</h5>
                                    <p>View all booking  details</p>
                                </div>
                            </div>
                        </Link>
                    </div>: "" }

                    { this.state.userDetails.category == "agent" ?
                    <>
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/allBooking" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={SavedProperty} alt="" />
                                    <h5 className='mt-3'>My bookings</h5>
                                    <p>View all booking  details</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    
                     <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/payment" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={Payment} alt="" />
                                    <h5 className='mt-3'>Payment</h5>
                                    <p> Keep track of all payment</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    </>
                    : ""
                    }
                    { this.state.userDetails.category == "affiliate" ?
                         <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/payment" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={Payment} alt="" />
                                    <h5 className='mt-3'>Payment</h5>
                                    <p> Keep track of all payment</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""
                    }
                    { this.state.userDetails.category == "superadmin" ?

                    <>
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/allBookings" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={SavedProperty} alt="" />
                                    <h5 className='mt-3'>All Reservations</h5>
                                    <p>View all booking  details</p>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/allpayment" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={Payment} alt="" />
                                    <h5 className='mt-3'>Payment</h5>
                                    <p> Keep track of all payment</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                     <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/allagent" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={AllAgent} alt="" />
                                    <h5 className='mt-3'>Agent</h5>
                                    <p style={{ lineHeight: 1.5 }}>View details about agents</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    </>
                    : ""
                    }

                    { this.state.userDetails.category == "superadmin" ?
                     <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/allaffiliates" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={AllAffiliates} alt="" />
                                    <h5 className='mt-3'>Affiliate</h5>
                                    <p style={{ lineHeight: 1.5 }}>View details about affiliate</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""
                    }

                    { this.state.userDetails.category == "superadmin" ?
                     <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/allProperty" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={AddProperty} alt="" />
                                    <h5 className='mt-3'>Property</h5>
                                    <p style={{ lineHeight: 1.5 }}>View details about property</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""
                    }


                    { this.state.userDetails.category == "superadmin" ?
                     <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/allguest" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={AllClient} alt="" />
                                    <h5 className='mt-3'>Guest</h5>
                                    <p style={{ lineHeight: 1.5 }}>Sign up as a client</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""
                    }


                    { this.state.userDetails.category == "client" ?
                     <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/savedproperty" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={SavedPropertys} alt="" />
                                    <h5 className='mt-3'>Saved property</h5>
                                    <p>View all saved property</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""

                    }


                    
                    { this.state.userDetails.category != "superadmin" ?
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/profile" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={Profile} alt="" />
                                    <h5 className='mt-3'>My profile</h5>
                                    <p> View my property details</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""

                    }

                   
                    { this.state.userDetails.category != "superadmin" ?
                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <Link className='d-flex justify-content-center icon-images' to="/contactus" style={{ width: '100%' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={Call} alt="" />
                                    <h5 className='mt-3'>Contact us</h5>
                                    <p>Send Us Message Today</p>
                                </div>
                            </div>
                        </Link>
                    </div>: ""

                    }


                    <div data-aos="fade-up" data-aos-duration="1000" className="col-lg-3 d-flex align-items-center m-0 p-2 justify-content-center">
                        <div className='d-flex justify-content-center icon-images' onClick={this.handleLogout} style={{ width: '100%', cursor: 'pointer' }}>
                            <div className='icon-wrapper col-lg-12'>
                                <div className='text-center'>
                                    <img style={{ height: '80px' }} src={LogOut} alt="" />
                                    <h5 style={{ color: '#019D9E' }} className='mt-3'>Log out</h5>
                                    <p style={{ color: '#019D9E' }}>Logout of my account</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
                </div>


            </>
        );
    }
}
 
export default Contact;