import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"

import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Twitter from "../Assets/images/Twitter - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import Landing5 from "../Assets/images/Rectangle 281.png"

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

class Contact extends Component {
    state = {  } 
    handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }
    render() { 
        return (
            <>
            <ToastContainer />
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Affiliates
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>Join Now</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='ml-3'>
                                <Link to='/category' style={{ background: '#019D9E', borderRadius: '10px' }}>
                                    <button style={{ background: '#019D9E', fontSize: '12px', borderRadius: '10px' }} className='btn text-white'>Join Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
               


               <div className='firsts'>
                    <p className='text-center first-text mt-5 p-3'>Fequently Asked Questions</p>   
                   
                   <div className="col-lg-12" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>How do I book on Riz Estate?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '40px', paddingBottom: '40px' }} className="collapse" id="navbarToggleExternalContent">               
                    <div>
                      <span className="text-dark text-justify">ChipIn is a wishlist & fundraising platform that allows you to raise funds for your cause or project. You can create a campaign and share it with others, who can then donate to your cause. If you're raising money for an emergency, like medical bills or a family tragedy, they can all chip in directly through our platform.</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#46266E', marginTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>

            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>Are there any extra charges or fees?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  





            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>When and how do I pay?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  

            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>What's Riz Estate’s online check-in process?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>How will Riz Estate verify my identity?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>Why do I need to create an account to make a reservation?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>Why was my booking canceled?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>How do I cancel my stay?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>What items are provided in a Riz Estate?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>What parking options are available?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>I want to talk to you about a recent stay, how do I do that?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>  
            <div className="col-lg-12 boxs-update" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-8">
                            <div className="row p-5 d-flex align-items-center justify-content-center">
                                <div className="col-lg-12 d-flex">
                                    <div>
                                        <p className='lko'>How do I find or report a lost item?</p>
                                        <div style={{ marginTop: '-35px', paddingTop: '30px', paddingBottom: '30px' }} className="collapse" id="navbarToggleExternalContentNew">               
                    <div>
                      <span className="text-dark text-justify">Yes! Once your campaign is created, people can donate immediately. You'll be able to see how much money has been donated over time under "Contributions" in the left-hand column of your dashboard. Terms and conditions apply</span>
                    </div>
                  </div>
                  
                                    </div>
                                    <div className='ml-auto navbar-toggler' type="button" data-toggle="collapse" data-target="#navbarToggleExternalContentNew" aria-controls="navbarToggleExternalContentNew" aria-expanded="false" aria-label="Toggle navigation">
                                        <i style={{ fontSize: '26px', color: '#3C096C', paddingTop: '-5px' }} className='bx bx-plus'></i>
                                    </div>
                                </div>
                            </div>
                    
                </div>
            </div>
            </div>   
            <div style={{ width: '100%' }} data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" className='faq-footer text-center'>
                <p>If you can’t find answer to the your question in FAQ, you can also <span style={{ color: '#019D9E' }}><Link to="/contact"> Contact Us </Link></span></p>
            </div>
            </div>







                


                <footer data-aos="fade-up" data-aos-duration="1000">
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/support">Support</a></li>
                        <li className='mt-3'><a className='text-white' href="/team">Team</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
            </>
        );
    }
}
 
export default Contact;