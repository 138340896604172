import React, { Component } from 'react';
import Logo from "../Assets/images/Riz secondary logo.png"
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Twitter from "../Assets/images/Twitter - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"

import Kitchen from "../Assets/images/Vector - 2022-10-23T194239.207.png"
import Location from "../Assets/images/Outline/Navigation/Icon.png"
import BackYard from "../Assets/images/Vector - 2022-10-23T194246.325.png"
import Balcony from "../Assets/images/Vector - 2022-10-23T194252.361.png"
import WashingMachine from "../Assets/images/icon-park-outline_washing-machine.png"
import Television from "../Assets/images/eva_tv-outline.png"
import Dining from "../Assets/images/Vector - 2022-10-23T194315.573.png"
import Refrigerator from "../Assets/images/Group (42).png"
import Swimmingpool from "../Assets/images/la_swimming-pool.png"
import Beachview from "../Assets/images/fluent_beach-20-regular.png"
import Smoking from "../Assets/images/foundation_no-smoking.png"
import Time from "../Assets/images/bx_time.png"
import Parties from "../Assets/images/bx_party.png"
import Pet from "../Assets/images/dashicons_pets.png"
import axios from 'axios';

import userImg from "../Assets/images/user.png"
import Notificationss from "../Assets/images/Solid/Status/Notification.png"
import { toast, ToastContainer } from 'react-toastify';

import { Link } from 'react-router-dom';
import ListingImage from './Common/listingSingleImage';

class Rent extends Component {
    state = { 
        allProperty: [],
        month: ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        allImage: [],
        userImage: ""
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          const fetchAllListing = await axios.get(`https://alim.com.ng/getSingleProperty/${user.email}/${this.props.match.params.id}`);
          this.setState({ allProperty: fetchAllListing.data.getSingleProperty })
          const allImage = fetchAllListing.data.updateImage;
          this.setState({ allImage });


          const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }

    handlSearchChange = async(e) => {
        this.setState({activeSearch: e.target.id})
    }


     handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }

    render() { 
        return (
            <div>
                <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='confirm-wrapper' style={{ marginTop: -50 }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className='mt-5'>
                                    <div className='row'>
                                        <ListingImage images={this.state.allImage} propertyId={this.state.allProperty._id} />
                                    </div>

                                    <div className="row mt-4">
                                    <div className='col-lg-6'>
                                            <h5 className='pay-header'>{this.state.allProperty.bedroom} bedroom flat / {this.state.allProperty.subTypeProperty} apartment</h5>
                                            <div className='d-flex align-items-center'>
                                                <img className='mr-4' style={{ height: '20px' }} src={Location} alt="" />
                                                <p className='location-pay'>{this.state.allProperty.location}</p>
                                            </div>
                                        <hr style={{ marginTop: '30px' }} />
                                       
                                        <p className='pay-description mt-3'>{this.state.allProperty.description}</p>
                                        
                                        <hr style={{ marginTop: '30px' }} />


                                        <h5 className='policies mt-5 font-weight-bold'>What the place offer</h5>
                                        <div className='d-flex'>

                                        <div>
                                        
                                        
                                        {this.state.allProperty.backyard == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={BackYard} alt="" />
                                            <p style={{ fontSize: 18 }}>Back yard</p>
                                        </div>: ""
                                        }
                                        {this.state.allProperty.balcony == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Balcony} alt="" />
                                            <p style={{ fontSize: 18 }}>Balcony</p>
                                        </div>: ""
                                        }
                                        {this.state.allProperty.washingmachine == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={WashingMachine} alt="" />
                                            <p style={{ fontSize: 18 }}>Washing Machine</p>
                                        </div>: ""
                                        }
                                        {this.state.allProperty.television == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Television} alt="" />
                                            <p style={{ fontSize: 18 }}>Television</p>
                                        </div>: ""
                                        }
                                        {this.state.allProperty.refrigerator == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Refrigerator} alt="" />
                                            <p style={{ fontSize: 18 }}>Refrigerator</p>
                                        </div>: ""
                                        }

                                        </div>

                                        <div className='ml-5'>

                                        {this.state.allProperty.dinning == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Dining} alt="" />
                                            <p style={{ fontSize: 18 }}>Dinning</p>
                                        </div>: ""
                                        }
                                        {this.state.allProperty.swimmingpool == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Swimmingpool} alt="" />
                                            <p style={{ fontSize: 18 }}>Swimming pool</p>
                                        </div>: ""
                                        }
                                        {this.state.allProperty.beachview == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Beachview} alt="" />
                                            <p style={{ fontSize: 18 }}>Beach view</p>
                                        </div>: ""
                                        }

                                        {this.state.allProperty.carpark == "on" ? 
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Balcony} alt="" />
                                            <p style={{ fontSize: 18 }}>Car Park</p>
                                        </div>: ""
                                        }

                                        </div>
                                        </div>

                                        <br />
                                        <h5 className='policies mt-5 font-weight-bold'>Rules to know</h5>
                                        <div className='d-flex'>


                                        <div>
                                            <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Smoking} alt="" />
                                            <p style={{ fontSize: 18 }}>{ this.state.allProperty.allowsmoking == "yes" ? "Smoking Allow" : "No Smoking"}</p>
                                        </div>
                                        
                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Time} alt="" />
                                            <p style={{ fontSize: 18 }}>Check-in: { this.state.allProperty.checkInTime }</p>
                                        </div>

                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Time} alt="" />
                                            <p style={{ fontSize: 18 }}>Check-out: { this.state.allProperty.CheckOutTime }</p>
                                        </div>

                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Parties} alt="" />
                                            <p style={{ fontSize: 18 }}>{ this.state.allProperty.allowparty == "yes" ? "Allow Party" : "No parties or events"} </p>
                                        </div>

                                        <div className='d-flex mt-4 align-items-center'>
                                            <img className='mr-4' style={{ height: 25 }} src={Pet} alt="" />
                                            <p style={{ fontSize: 18 }}>{ this.state.allProperty.allowpet == "yes" ? "Allow Pet" : "No Pets"}</p>
                                        </div>

                                        </div>
                                        
                                        </div>

                                        
                                    </div>
                                        <div className="col-lg-6">
                                            <div className='d-flex'>
                                                <button className='ml-auto btn paynow' style={{ background: "white", border: '1px solid #019D9E', color: "#019D9E" }}>EDIT THIS LISTING</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
   


                 <footer data-aos="fade-up" data-aos-duration="1000">
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/support">Support</a></li>
                        <li className='mt-3'><a className='text-white' href="/team">Team</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
            </div>
        );
    }
}
 
export default Rent;