import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import userImg from "../Assets/images/user.png"
import Uploader from "../Assets/images/bi_cloud-upload-fill.png"
import Notificationss from "../Assets/images/Solid/Status/Notification.png"
import bulletBar from "../Assets/images/Ellipse 6.png"
import Progress from "../Assets/images/progres2.png"
import Trash from "../Assets/images/Outline/Interface/Trash.png"
import { BallTriangle } from  'react-loader-spinner'

class Contact extends Component {

    state = {
        user: {},
        file: null,
        month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        date: "",
        userImage: "",
        bulkImage: [],
        display: 'none',
        show: false,
    }

    handleImages = async (e) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('id', this.props.match.params.id);
        formData.append('file', file);
        this.setState({ display: "flex" })
        const sendData = await axios.post('https://alim.com.ng/propertyImage', formData);
        if(sendData.data.statusCode == 200){
            toast.success(sendData.data.statusMessage);
            this.setState({ bulkImage: sendData.data.update });
            this.setState({ display: "none" })
        }else {
            toast.error(sendData.data.statusMessage);
            this.setState({ display: "none" })
        }
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
            const getImages = await axios.get(`https://alim.com.ng/getImage/${this.props.match.params.id}`);
            this.setState({ bulkImage: getImages.data.update });
            const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }

    handleLogout = () => {
        sessionStorage.clear();
        this.props.history.push("/login");
    }

    handleDeleteImage = async (e) => {
        const id = e.target.id;

        const payload = {
            id
        }
        const deleteImage = await axios.post('https://alim.com.ng/deleteImage', payload);
        if(deleteImage.data.statusCode == 200){
            toast.success(deleteImage.data.statusMessage);
            this.setState({ bulkImage: deleteImage.data.update });
        }else {
            toast.error(deleteImage.data.statusMessage);
        }
    }

     handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }

    handleImage = (e) => {
        e.preventDefault();
        this.inputElement.click();
        this.handleImages()
    }


    render() { 
        
        return (
            <>
            <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
            <ToastContainer />
            <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
        
            <div className='mt-4 text-center'>
                <h4 style={{ color: '#019D9E', fontWeight: '700', fontSize: '30px' }}>Listing created successful</h4>
                <p style={{ fontSize: '15px' }}>Go back to dashboard to view all listings</p>
                <div className='mt-3' style={{ width: '100%' }}>
                    <a href='/dashboard' type='button' className='p-2' style={{ background: '#019D9E', border: '0px', borderRadius: '5px', color: 'white', width: '100%' }}>MY DASHBOARD</a>
                </div>
            </div>
      </div>
    </div>
  </div>

            </div>
                <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Affiliates
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link> 
                            </ul>
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

              <div className='wrapper-contact-form pb-4'>
                  <Link to="/dashboard">
                    <div className='d-flex align-items-center pt-4'>
                        <i className='bx iconp bx-chevron-left mr-3'></i>
                        <p>Back to Dashboard</p>
                    </div>
                  </Link>

                  <div className='mt-4'>
                      <h5>Add listings</h5>
                      <p>Upload your property in this section</p>
                  </div>
              </div>

              <div className='d-flex justify-content-center mt-5' style={{ width: '100%', height: '93px' }}>
                <img style={{ width: '80%' }} src={Progress} id="progrees-b" />
              </div>

            <div className='d-flex justify-content-center mt-3'>
                <div className="col-lg-10">
                    <div className="row">
                        { this.state.bulkImage.map(singleData =>  
                            <div key={singleData._id} className="col-lg-3 mb-4">
                                <div style={{ width: '100%' }}>
                                <img style={{ width: '100%', height: '150px' }} src={"https://alim.com.ng/images/" + JSON.parse(singleData.image).filename} alt="imahge wrap" />
                            <div className='d-flex' style={{ border: '1px solid #019D9E', height: '45px' }}>
                                        <div className='d-flex justify-content-center align-items-center text-white' style={{ background: '#019D9E', width: '30%' }}>
                                            Order
                                        </div>
                                    <div style={{ width: '70%' }}>
                                        <select className='form-control' style={{ borderRadius: '0px', border: '0px', height: '43px' }} name="" id="">
                                            <option value="">{singleData.position}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex mt-2 justify-content-center'>
                                <button onClick={this.handleDeleteImage} id={singleData._id} className='d-flex align-items-center p-2' style={{ border: '1px solid #019D9E', background: 'white', color: '#019D9E', borderRadius: '5px' }}>
                                    <img id={singleData._id} className='mr-1' style={{ height: '25px' }} src={ Trash } alt="" />
                                    <p id={singleData._id}>Delete</p>
                                </button>
                            </div>
                        </div> 
                        )}
                    </div>
                </div>
            </div>
              
              <div className="col-lg-12 mb-5 mt-5 contact-form">
                  <div className="row">
                      <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '150px', padding: 10 }}>Upload pictures</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleUpdate}>
                                <div className='d-flex justify-content-center'>
                                    <div className='text-center'>
                                        <img style={{ height: '150px' }} src={Uploader} />
                                        
                                        <div className='d-flex justify-content-center'>
                                            <input name='ko' onChange={this.handleImages} ref={input => this.inputElement = input} id="upload" type="file" accept='.png ,.jpg, ' />
                                            <button className='' onClick={this.handleImage}  style={{ color: '#019D9E', fontSize: '13px', fontWeight: 'bold', paddingRight: '40px', paddingLeft: '40px', background: 'transparent', border: '1px solid #019D9E' }}>Browse device</button>
                                        </div>
                                        <div className='text-center mt-3 mb-3 justify-content-center'>
                                            <div className='text-center'>
                                                <div className='d-flex mb-3 mt-3 justify-content-center text-center align-items-center'>
                                                    <img src={bulletBar} style={{ height: '5px' }} />
                                                    <p className='text-center ml-1' style={{ color: '#B3B3B3', fontWeight: 'normal', fontSize: '16px' }}>Maximum of 25 pictures</p>
                                                </div>

                                                <div className='d-flex mb-3 mt-3 justify-content-center text-center align-items-center'>
                                                    <img src={bulletBar} style={{ height: '5px' }} />
                                                    <p className='text-center ml-1' style={{ color: '#B3B3B3', fontWeight: 'normal', fontSize: '16px' }}>Minimum of 4 pictures</p>
                                                </div>

                                                <div className='d-flex mb-3 mt-3 justify-content-center text-center align-items-center'>
                                                    <img src={bulletBar} style={{ height: '5px' }} />
                                                    <p className='text-center ml-1' style={{ color: '#B3B3B3', fontWeight: 'normal', fontSize: '16px' }}>Maximum file size is 10MB</p>
                                                </div>

                                                <div className='d-flex mb-3 mt-3 justify-content-center text-center align-items-center'>
                                                    <img src={bulletBar} style={{ height: '5px' }} />
                                                    <p className='text-center ml-1' style={{ color: '#B3B3B3', fontWeight: 'normal', fontSize: '16px' }}>1600*1200 file size is recommended</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              
              


              <div className='d-flex justify-content-center mb-4'>
                  <button disabled={this.state.bulkImage.length >= 5 ? false: true} data-toggle="modal" data-target="#staticBackdrop" style={{ background: '#019D9E', color: 'white', width: '90%', border: '0px', padding: '17px', borderRadius: '10px' }}>FINISH</button>
              </div>
            </>
        );
    }
}
 
export default Contact;