import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import Logo from "../../Assets/images/Riz primary logo.png"
import {toast, ToastContainer } from "react-toastify";
import { BallTriangle } from  'react-loader-spinner'
import axios from 'axios';

class Login extends Component {
    
    state = { 
        showPasswordText: 'See', 
        showPassword1: false,
        display: "none"
    } 
    
    showPassword = () => {
        if(this.state.showPasswordText === "See"){
           this.setState({ showPasswordText: 'Hide', showPassword1: true }) 
        }else{
           this.setState({ showPasswordText: 'See', showPassword1: false }) 
        }
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/dashboard');  
        }
        else {
            console.log(JSON.parse(sessionStorage.getItem('userDetails')))
            if(sessionStorage.getItem('activate') == "yes"){
                toast.success("Email Activation Successfully");
                sessionStorage.clear()
            }else if(sessionStorage.getItem('activate') == "no"){
                toast.error("Link is Invalid");
                sessionStorage.clear()
            }else {
                return null
            }
            this.props.history.push('/login');
        }
    }

    handleLogin = async (e) => {
        e.preventDefault();
        this.setState({ display: "flex" })
        const payload = {
            email: document.querySelector('#email').value,
            password: document.querySelector('#password').value
        }
        
        try {
            const sendRequest = await axios.post('https://alim.com.ng/login', payload);
            if(sendRequest.data.status == 200){
                toast.success('Login Successfully');
                const user = {
                    fullName: sendRequest.data.user.fullName,
                    category: sendRequest.data.user.category,
                    email: sendRequest.data.user.email,
                }

                setTimeout(() => {
                    sessionStorage.setItem("userDetails", JSON.stringify(user));
                    this.props.history.push('/dashboard');
                }, 3000)

            }else{
                toast.error('Invalid Credentials');  
                this.setState({ display: "none" })              
            }
        }catch(e){  
            toast.error('Invalid Credentials');
            this.setState({ display: "none" })
        }

    }

    render() { 
        return (
            <>
            <ToastContainer />
              <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
              </div>
              <div className='col-lg-12 login'>
                  <div className="row">
                    <div className="col-lg-6 side-one-login" data-aos="fade-left" data-aos-duration="1000">
                        <div className='mt-5'>
                            <Link to="/">
                                <img src={Logo} alt="" />
                            </Link>
                        </div>

                        <div className='signin-caption'>
                            <h4>Log in</h4>
                            <p>To keep connected with us please login with your personal info.</p>
                        </div>
                        <form autocomplete="off" onSubmit={this.handleLogin}>
                            <div className='mt-5'>
                                <label htmlFor="">Your email <span style={{ fontSize: '20px' }} className='text-danger'>*</span></label>
                                <input className='form-control' id='email' type="email" placeholder='Please enter your email' required />
                            </div>

                            <div className='mt-4'>
                                <label htmlFor="">Password <span style={{ fontSize: '20px' }} className='text-danger'>*</span></label>
                                    <input className='form-control' type={this.state.showPassword1 == true ? "text" : "password"} id='password' placeholder='Enter password' required/>
                                <div className='d-flex bg-primary' style={{ width: '97%' }}>
                                    <span onClick={this.showPassword} className='ml-auto forget-2' style={{ marginTop: -43, cursor: 'pointer' }}> {this.state.showPasswordText} </span>
                                </div>
                            </div>

                            <div className='d-flex align-items-center'>
                                <input className='mr-2' type="checkbox"  />
                                <label className='pt-2 remeber'>Remember me</label>
                            </div>

                            <div className='mt-4'>
                                <button type='submit' className='btn' style={{ background: '#019D9E' }}>LOGIN</button>
                            </div>
                        </form>

                        <div className="d-flex mt-3">
                            <p className='forget'><Link to="/forgotPassword">Forget password?</Link></p>
                            <p className='ml-auto forget forget-2'><Link to="/category">Create an account?</Link></p>
                        </div>
                    </div>
                    <div className="col-lg-6 bg-primary p-0 m-0 side-two-login" data-aos="fade-right" data-aos-duration="1000">
                        <div className='d-flex justify-content-center align-items-center'>
                            <div style={{ width: '90%' }}>
                                <h5 className='text-right text-white'>Easy way to secure a perfect property</h5>
                                <div style={{ paddingLeft: '200px' }}>
                                    <p className='text-right text-white short-letting'>short-letting has never been easier, upload your property and book a short-let reservation in minutes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>  
            </>
        );
    }
}
 
export default Login;