import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"

import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import Location from "../Assets/images/Outline/Navigation/Icon.png"
import Calendar from "../Assets/images/calendar_1_.png"
import Vector from "../Assets/images/Vector.png"
import Vector1 from "../Assets/images/Vector2.png"
import Vector2 from "../Assets/images/Vector3.png"
import Vector3 from "../Assets/images/Vector4.png"

import ListingImage from './Common/listingImage';

import userImg from "../Assets/images/user.png"
import Notificationss from "../Assets/images/Solid/Status/Notification.png"

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

class Contact extends Component {
    state = {
        user: {},
        allProperty: [],
        file: null,
        month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        date: "",
        userImage: "",
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/savedproperty');  
            const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }

     handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }
    render() { 
        return (
            <>
             <ToastContainer />
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

              <div className='wrapper-contact-form pb-4'>
                  <div className='d-flex align-items-center pt-4'>
                      <i className='bx iconp bx-chevron-left mr-3'></i>
                      <p>Back to Homepage</p>
                  </div>

                  <div className='mt-4'>
                      <h5>Saved property</h5>
                      <p>Explore all saved property</p>
                  </div>
              </div>


            <div className='saved-property mb-5'>
                <div className="landing-3" style={{ marginTop: '-40px' }}>
                        <div className='desktop-property'>
                    <div className="col-lg-12">
                        <div className="row align-items-center">
                            { this.state.allProperty.map(property => 
                            <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                                <div className='apartments'>
                                    <div className='d-flex p-0 m-0'>
                                        <div className='col-lg-6 p-0 m-0'>
                                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                        
                                          <ListingImage images={this.state.allImage} propertyId={property._id} />
                                          
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        
                                        </div>

                                        <div className='apartment-caption col-lg-6 d-flex align-items-center m-0 p-0'>
                                            <div style={{ width: '100%' }}>


                                            <div className='first-apart-section'>

                                            <Link to={`/property/${property._id}`}>

                                                <p className='apartment-name text-capitalize' style={{ fontSize: 30, lineHeight: 1.5}}>{property.bedroom} bedroom flat / {property.subTypeProperty} apartment</p>
                                            </Link>
                                                <div className='mt-4'>
                                                    <p className='text-justify' style={{ fontSize: 16, lineHeight: 1.5 }}>{property.description}</p>
                                                </div>
                                                <p className='apartment-location mt-3'> <img className='mr-2' style={{ height: '15px' }} src={Location} alt="" /> <u>{property.location}</u> </p>
                                                <div className='d-flex'>

                                                <div className='mt-5 more-detail d-flex align-items-center'>
                                                    <Link to={`/property/${property._id}`}>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                    </Link>
                                                </div>

                                                <div className='mt-5 more-detail d-flex align-items-center ml-auto'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  {new Date(property.dateRegistered).getDate()} {this.state.month[new Date(property.dateRegistered).getMonth()]} {new Date(property.dateRegistered).getFullYear()}</p>
                                                </div>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-12px' }} />
                                            <div className='d-flex second-section mt-3'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦ {property.price}</h6> <p>Per day</p>
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex mt-3">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>{property.bedroom} Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>{property.toilet} Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>{property.bathroom} Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                                                                        </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            
                            
                            ) }
                                                    </div>




                         </div>       
                        </div>
    <div className='mobile-property'>
<div className="row">

{ this.state.allProperty.map(property => 

    <div className="col-lg-4 mb-5">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <ListingImage images={this.state.allImage} propertyId={property._id} />
                                          
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        
                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>
                                                <Link to={`/property/${property._id}`}>
                                                <p className='apartment-name' style={{ fontSize: 23, lineHeight: 1.5 }}>{property.bedroom} bedroom flat / {property.subTypeProperty} apartment</p>
                                                </Link>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /><u>{property.location}</u></p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>{property.description}</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <Link to={`/property/${property._id}`}>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                    </Link>
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  {new Date(property.dateRegistered).getDate()} {this.state.month[new Date(property.dateRegistered).getMonth()]} {new Date(property.dateRegistered).getFullYear()}</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦ {property.price}</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    { property.status == "pending" ? <img style={{ height: '30px' }} src={Pending} alt="" /> : property.status == "approved" ? <img style={{ height: '30px' }} src={Approved} alt="" /> : <img style={{ height: '30px' }} src={Rejected} alt="" /> }
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>{property.bedroom} Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>{property.toilet} Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>{property.bathroom} Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
) }
</div>
                    </div>
                    </div>
                   
            </div>
              
              

                    <footer data-aos="fade-up" data-aos-duration="1000">
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/support">Support</a></li>
                        <li className='mt-3'><a className='text-white' href="/team">Team</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
            </>
        );
    }
}
 
export default Contact;