import axios from 'axios';
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import { toast, ToastContainer } from 'react-toastify';
import Logo from "../../Assets/images/Riz primary logo.png"
import { BallTriangle } from  'react-loader-spinner'

class Login extends Component {
    state = { 
        display: "none"
    } 

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ display: "flex" })
        const payload = {
            email: document.querySelector('#email').value
        }

        const postEmail = await axios.post("https://alim.com.ng/forgotPassword", payload);
        
        if(postEmail.data.statusCode == 200){
            toast.success("Password Reset Mail Has Been Sent To Your Mail");
            document.querySelector('#email').value = ""
            this.setState({ display: "none" })
        }else {
            toast.error("Email Is Not Found");
            this.setState({ display: "none" })
        }
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/dashboard');  
        }
    }

    render() { 
        return (
            <>
            <ToastContainer />
            <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
              <div className='col-lg-12 login'>
                  <div className="row">
                    <div className="col-lg-6 side-one-login" data-aos="fade-right" data-aos-duration="1000">
                        <div className='mt-5'>
                            <Link to="/">
                                <img src={Logo} alt="" />
                            </Link>
                        </div>

                        <div className='signin-caption'>
                            <h4>Forgot Password</h4>
                            <p>Kindly input your email address to retrieve your password.</p>
                        </div>
                        <form autocomplete="off" onSubmit={this.handleSubmit }>
                            <div className='mt-5'>
                                <label htmlFor="">Your email<span className='text-danger'>*</span></label>
                                <input id='email' className='form-control' type="email" placeholder='Please enter your email' required />
                            </div>

                            <div className='mt-4'>
                                <button type='submit' style={{ background: '#019D9E' }} className='btn'>FORGOT PASSWORD</button>
                            </div>
                        </form>

                            <div className="d-flex mt-3">
                                <p className='forget'><Link to="/login"> Login Now</Link></p>
                                <p className='ml-auto forget forget-2'><Link to="/register">Create an account?</Link></p>
                            </div>
                            </div>
                        <div className="col-lg-6 bg-primary p-0 m-0 side-two-login" data-aos="fade-left" data-aos-duration="1000">
                        <div className='d-flex justify-content-center align-items-center'>
                            <div style={{ width: '90%' }}>
                                <h5 className='text-right text-white'>Easy way to secure a perfect property</h5>
                                <div style={{ paddingLeft: '200px' }}>
                                    <p className='text-right text-white short-letting'>short-letting has never been easier, upload your property and book a short-let reservation in minutes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>  
            </>
        );
    }
}
 
export default Login;