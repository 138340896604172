import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"

import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Twitter from "../Assets/images/Twitter - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"

import backRight from "../Assets/images/Frame 103.png"
import footerAbout from "../Assets/images/Group 5683.png"

import TeamImage from "../Assets/images/Frameda.png"

import axios from 'axios';
import { ToastContainer, toast } from "react-toastify"

class Contact extends Component {
    state = {  } 

    handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }

    render() { 
        return (
            <>
            <ToastContainer />
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORT-LETS
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       AFFILIATES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/category'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>Join Now</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand" style={{ marginTop: '-10px' }}>
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '30px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center' style={{ marginTop: '-10px' }}>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORT-LETS</Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>AFFILIATES</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='ml-3' style={{ marginTop: '-10px' }}>
                                <Link to='/register' style={{ background: '#019D9E', borderRadius: '10px' }}>
                                    <button style={{ background: '#019D9E', borderRadius: '10px' }} className='btn text-white text-capitalize'>Join Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="team-header" >
                    <h5 data-aos="fade-up" data-aos-duration="1000">Meet the Riz Team</h5>
                </div>

                <div className='team-1'>
                    <div className="col-lg-12">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                                <div className="img-wrapper" style={{ width: '100%', marginBottom: '20px' }}>
                                    <img style={{ width: '100%' }} src={TeamImage} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                                <div className='team-1-side'>
                                    <h4>Abdullahi habeeb</h4>
                                    <p className='desc1'>CEO & FOUNDER</p>
                                    <p className='desc2'> RIZ ESTATE</p>
                                    <p className='mt-4 descript'>Hotels aren’t always hip. Hosts aren’t always reliable. So we’re changing the game with spaces that inspire and delight, that fulfill needs without sacrificing style, all while delivering seamless, personalized experiences. Check in, request fresh towels, and get dinner recommendations—all from your phone.</p>
                                    <p className='mt-4 descript'>By eliminating inefficiencies as we grow, we can deliver hospitality that’s both remarkable and accessible. Because everyone should be able to afford an extraordinary place to stay.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                  <footer data-aos="fade-up" data-aos-duration="1000">
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/support">Support</a></li>
                        <li className='mt-3'><a className='text-white' href="/team">Team</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
            </>
        );
    }
}
 
export default Contact;