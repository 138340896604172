import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"

import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import Notificationss from "../Assets/images/Solid/Status/Notification.png"
import userImg from "../Assets/images/user.png"

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';


class Contact extends Component {
    state = { 
        allPayment: [],
        month: ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        allImage: [],
        userImage: ""
    } 

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/allProperty');  
          const fetchAllListing = await axios.get(`https://alim.com.ng/getAllProperty`);
          this.setState({ allPayment: fetchAllListing.data.allProperty })

          const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }

    handleApprove = async (id) => {
        const handls = await axios.post('https://alim.com.ng/approveProperty', {id: id.target.id});
        if(handls.data.statusCode == 200){
            this.setState({ allPayment: handls.data.findAllProperty })
            toast.success("Approved Successfully");
        }
    }

    handleReject = async (e) => {
        e.preventDefault();
        const reason = document.querySelector('#damiss' + e.target.id);
        const handls = await axios.post('https://alim.com.ng/rejectProperty', {id: e.target.id, reason: reason.value});
        if(handls.data.statusCode == 200){
            this.setState({ allPayment: handls.data.findAllProperty })
            toast.success("Rejected Successfully");
        }
    }

    handleDelete = async (id) => {
        const deleteUser = await axios.post('https://alim.com.ng/deleteGuest', {email: id.target.id});
        console.log(deleteUser)
        window.location.href = "/allguest"
    }

     handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }
    render() { 
        return (
            <>
            <ToastContainer />
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
               


              <div className='wrapper-contact-form pb-4'>
                  <div className='d-flex align-items-center pt-4'>
                      <i className='bx iconp bx-chevron-left mr-3'></i>
                      <p>Back to Homepage</p>
                  </div>

                  <div className='mt-4'>
                      <h5>All Property</h5>
                      <p>View All Property</p>
                  </div>
              </div>

            <div className='table-head table-responsive d-flex justify-content-center'>
              <table class="table">
                <thead style={{ background: '#EDEDED', border: 0, borderRadius: 8 }}>
                  <tr>
                    <th style={{ fontSize: 15 }} scope="col">Property name</th>
                    <th style={{ fontSize: 15 }} scope="col">Location</th>
                    <th style={{ fontSize: 15 }} scope="col">Cost</th>
                    <th style={{ fontSize: 15 }} scope="col">Date Added</th>
                    <th style={{ fontSize: 15 }} scope="col">Status</th>
                    <th style={{ fontSize: 15 }} scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                    { this.state.allPayment.map(bookings => 
                    <tr>
                        <td style={{ color: "#2B2B2B", fontSize: 13 }} className='text-capitalize'>{ bookings.title }</td>
                        <td style={{ color: "#2B2B2B", fontSize: 13 }} className='text-capitalize'>{ bookings.location }</td>
                        <td style={{ color: "#2B2B2B", fontSize: 13 }}>{ bookings.price }</td>
                        <td style={{ color: "#2B2B2B", fontSize: 13 }} className='text-capitalize'>{ bookings.dateRegistered }</td>
                        
                        <td style={{ color: "#2B2B2B", fontSize: 13 }} className='text-capitalize'>{bookings.status}</td>
                        <td style={{ color: "#2B2B2B", fontSize: 13 }} className='text-capitalize'>
                            <Link to={`/property/${bookings._id}`}>
                                <button className='btn btn-warning font-weight-bold mr-2 btn-sm'>View</button>
                            </Link>

<div class="modal fade" id={"dami" + bookings._id} data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Reason For Rejection</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <div>
            <form onSubmit={this.handleReject} id={bookings._id}>
                <input id={"damiss" + bookings._id} className='form-control' type="text" required />
                <div className='d-flex justify-content-center mt-3'>
                
                    <button id={bookings._id} type='submit' className='btn btn-sm btn-success text-white'>Submit</button>
                </div>
            </form>
          </div>
      </div>
    </div>
  </div>
</div>


                            { bookings.status == "approved" || bookings.status == "rejected" ? "": <> <button onClick={this.handleApprove} id={bookings._id} className='btn btn-success font-weight-bold mr-2 btn-sm'>Approve</button> <button data-toggle="modal" data-target={ "#dami" + bookings._id} className='btn btn-danger font-weight-bold btn-sm'>Reject</button> </>}
                        </td>
                    </tr>
                    )}
                </tbody>
              </table>
            </div>

                <footer data-aos="fade-up" data-aos-duration="1000">
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/support">Support</a></li>
                        <li className='mt-3'><a className='text-white' href="/team">Team</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
            </>
        );
    }
}
 
export default Contact;