import React, { Component } from 'react';
import Image1 from "../../Assets/images/unsplash_yETqkLnhsUI.png"

class ListingImage extends Component {
    state = { 
        images: this.props.images,
        propertyId: this.props.propertyId,
        perCampaign: [],
        singleImage: ""
     } 

     async componentDidMount(){
         console.log(this.state.propertyId)
         const filterData = await this.state.images.filter(a => a.propertyId == this.state.propertyId)
         
         this.setState({ perCampaign: filterData, singleImage: JSON.parse(filterData[0].image).filename });
     }
    render() { 
        return (
            <>
            
            <div key={this.state.singleImage._id} class="carousel-item active">
                <img class="d-block w-100" style={{ height: '450px', objectFit: 'cover' }} src={"https://alim.com.ng/images/" + this.state.singleImage} alt="First slide"/> 
            </div>
            {this.state.perCampaign.map(check => 
                    <div key={check._id} class="carousel-item">
                        <img class="d-block w-100" style={{ height: '450px', objectFit: 'cover' }} src={"https://alim.com.ng/images/" + JSON.parse(check.image).filename} alt="First slide"/> 
                    </div>
                )}
            </>
        );
    }
}
 
export default ListingImage;