import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import { BallTriangle } from  'react-loader-spinner'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import userImg from "../Assets/images/user.png"
import Notificationss from "../Assets/images/Solid/Status/Notification.png"

import Progress from "../Assets/images/firstreg.png"

class Contact extends Component {

    state = {
        user: {},
        file: null,
        month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        date: "",
        display: "none",
        userImage: "",
    }

     handleImages = (e) => {
        let file = e.target.files[0];
        this.setState({ file: file });
        console.log(file)
    }

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/addProperty');  
            const payload = {
                email: user.email
            }
            const fetchUser = await axios.post('https://alim.com.ng/profile', payload);
            const newImage = fetchUser.data.user.image === "" ? "" : JSON.parse(fetchUser.data.user.image).filename
            this.setState({ user: fetchUser.data.user, userImage: newImage})
        }else {
            this.props.history.push('/login'); 
        }
    }

    handleLogout = () => {
        sessionStorage.clear();
        this.props.history.push("/login");
    }

     handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
            }else {
                toast.error(newsletter.data.statusMessage);
            }
        }else {
            toast.error("Please input a valid email address")
        }
    }

    

    handleImageSubmit = async (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('email', this.state.user.email);
        const postImage = await axios.post('https://alim.com.ng/profileImage', formData);
        if(postImage.data.statusCode == 200){
            toast.success('Successfully Updated')
            setTimeout(() => {
                window.location.href = "/profile"
            },3000)
        }else {
            toast.error("Something Went Wrong")
        }
    }

    addNewProperty = async () => {
        this.setState({ display: "flex" })
        
        const payload = {
            title: document.querySelector('#title').value,
            propertyStatus: document.querySelector('#propertyStatus').value,
            category: document.querySelector('#category').value,
            typeOfProperty: document.querySelector('#typeOfProperty').value,
            subTypeProperty: document.querySelector('#subTypeProperty').value,
            location: document.querySelector('#location').value,
            state: document.querySelector('#state').value,
            currencyType: document.querySelector('#currencyType').value,
            price: document.querySelector('#price').value,
            bathroom: document.querySelector('#bathroom').value,
            bedroom: document.querySelector('#bedroom').value,
            toilet: document.querySelector('#toilet').value,
            description: document.querySelector('#description').value,
            allowsmoking: document.querySelector('#allowsmoking').value,
            allowparty: document.querySelector('#allowparty').value,
            allowpet: document.querySelector('#allowpet').value,
            checkInTime: document.querySelector('#checkInTime').value,
            CheckOutTime: document.querySelector('#CheckOutTime').value,
            kitchen: document.querySelector('#kitchen').value,
            backyard: document.querySelector('#backyard').value,
            balcony: document.querySelector('#balcony').value,
            television: document.querySelector('#television').value,
            washingmachine: document.querySelector('#washingmachine').value,
            dinning: document.querySelector('#dinning').value,
            swimmingpool: document.querySelector('#swimmingpool').value,
            beachview: document.querySelector('#beachview').value,
            refrigerator: document.querySelector('#refrigerator').value,
            carpark: document.querySelector('#carpark').value,
            email: this.state.user.email
        }

        if(payload.title != "" && payload.propertyStatus != "" && payload.category != "" && payload.typeOfProperty != "" && payload.location != "" && payload.state != "" && payload.currencyType && payload.price != "" && payload.bathroom != "" && payload.bedroom != "" && payload.toilet != "" && payload.bedroom != ""){
            const sendRequest = await axios.post('https://alim.com.ng/addProperty', payload);
            if(sendRequest.data.statusCode == 200){
                toast.success('Property Saved Sucessfully');
                
            setTimeout(() => {
                window.location.href = `/addPropertyImage/${sendRequest.data.propertyId}`
            },3000)
        }else {
            toast.error("Something Went Wrong")
        }
        }else {
            toast.error('Please fill in all the availablle field');
        }
    }

    render() { 
        return (
            <>
            <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
            <ToastContainer />
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Affiliates
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link> 
                            </ul>
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='d-flex'>
                                <Link to="/notifications" className='mr-4'>
                                    <img style={{ height: '27px' }} src={Notificationss} alt="" />
                                </Link>

                                <Link to="/dashboard">
                                    <img style={{ height: '30px', borderRadius: 20 }} src={ this.state.userImage === "" ? userImg : "https://alim.com.ng/images/" + this.state.userImage }  alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

              <div className='wrapper-contact-form pb-4'>
                  <Link to="/dashboard">
                    <div className='d-flex align-items-center pt-4'>
                        <i className='bx iconp bx-chevron-left mr-3'></i>
                        <p>Back to Dashboard</p>
                    </div>
                  </Link>

                  <div className='mt-4'>
                      <h5>Add listings</h5>
                      <p>Upload your property</p>
                  </div>
              </div>

              <div className='d-flex justify-content-center mt-5' style={{ width: '100%', height: '93px' }}>
                <img style={{ width: "90%" }} src={Progress} id="progrees-b" alt="" />
              </div>

              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '150px', padding: 10 }} >Property details</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleUpdate}>

                                <div className="row" style={{ marginTop: '-50px' }}>

                                    <div className='col-lg-8 mb-5'>
                                        <label htmlFor="">Title<span style={{ fontSize: '16px', color: '#EF3061' }}>*</span></label>
                                        <input className='form-controls' placeholder="e.g Luxury 3 bedroom flat" type="text" id="title" />
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Property status</label>
                                        <select className='form-controls' name="" id="propertyStatus" placeholder="Select property status">
                                            <option value="">Select property status</option>
                                            <option value="not available">Not available</option>
                                            <option value="available">Available</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Category</label>
                                        <select className='form-controls' name="" id="category" placeholder="Select property status">
                                            <option value="">Select category</option>
                                            <option value="rent">Rent</option>
                                            <option value="Shortlet">Shortlet</option>
                                            <option value="Sale">Sale</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Type of property</label>
                                        <select className='form-controls' name="" id="typeOfProperty">
                                            <option value="">Select category</option>
                                            <option value="Flat">Flat</option>
                                            <option value="Apartment">Apartment</option>
                                            <option value="Land">Land</option>
                                        </select>
                                    </div>
                                    
                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Sub-type property(optional)</label>
                                        <select className='form-controls' name="" id="subTypeProperty">
                                            <option value="">Select sub-type property</option>
                                            <option value="Self-contain">Self-contain</option>
                                            <option value="Mini flat">Mini flat</option>
                                            <option value="Room and palour">Room and palour</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-8 mb-4'>
                                        <label htmlFor="">Location*</label>
                                         <input className='form-controls' placeholder="Location" type="text" id="location" />                                        
                                    </div>

                                    <div className='col-lg-4 mb-4'>
                                        <label htmlFor="">State</label>
                                        <select className='form-controls' name="" id="state" placeholder="Select property status">
                                            <option value="">Select state</option>
                                            <option value="Abia State">Abia State</option>
                                            <option value="Adamawa State">Adamawa State</option>
                                            <option value="Akwa Ibom State">Akwa Ibom State</option>
                                            <option value="Anambra State">Anambra State</option>
                                            <option value="Bauchi State">Bauchi State</option>
                                            <option value="Bayelsa State">Bayelsa State</option>
                                            <option value="Benue State">Benue State</option>
                                            <option value="Borno State">Borno State</option>
                                            <option value="Cross River State">Cross River State</option>
                                            <option value="Delta State">Delta State</option>
                                            <option value="Ebonyi State">Ebonyi State</option>
                                            <option value="Edo State">Edo State</option>
                                            <option value="Ekiti State">Ekiti State</option>
                                            <option value="Enugu State">Enugu State</option>
                                            <option value="Gombe State">Gombe State</option>
                                            <option value="Jigawa State">Jigawa State</option>
                                            <option value="Kaduna State">Kaduna State</option>
                                            <option value="Kano State">Kano State</option>
                                            <option value="Katsina State">Katsina State</option>
                                            <option value="Kebbi State">Kebbi State</option>
                                            <option value="Kogi State">Kogi State</option>
                                            <option value="Kwara State">Kwara State</option>
                                            <option value="Lagos State">Lagos State</option>
                                            <option value="Nasarawa State">Nasarawa State</option>
                                            <option value="Niger State">Niger State</option>
                                            <option value="Ogun State">Ogun State</option>
                                            <option value="Ondo State">Ondo State</option>
                                            <option value="Osun State">Osun State</option>
                                            <option value="Oyo State">Oyo State</option>
                                            <option value="Plateau State">Plateau State</option>
                                            <option value="Rivers State">Rivers State</option>
                                            <option value="Sokoto State">Sokoto State</option>
                                            <option value="Taraba State">Taraba State</option>
                                            <option value="Yobe State">Yobe State</option>
                                            <option value="Zamfara State">Zamfara State</option>
                                        </select>
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>



              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '150px', padding: 10 }} >Property pricing</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleUpdate}>

                                <div className="row" style={{ marginTop: '-50px' }}>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Select currency type<span style={{ fontSize: '16px', color: '#EF3061' }}>*</span></label>
                                        <select className='form-controls' name="" id="currencyType">
                                            <option value="">Select currency</option>
                                            <option value="naira">Naira</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Price</label>
                                         <input className='form-controls' placeholder="60,000" type="number" id="price" />
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Bathroom</label>
                                        <select className='form-controls' name="" id="bathroom" placeholder="bathroom">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>


              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '150px', padding: 10 }} >Property facility</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleUpdate}>

                                <div className="row" style={{ marginTop: '-50px' }}>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Bedrooms</label>
                                        <select className='form-controls' name="" id="bedroom">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Toilet</label>
                                        <select className='form-controls' name="" id="toilet" placeholder="Toilet">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Bathroom</label>
                                        <select className='form-controls' name="" id="bathroom" placeholder="Select property status">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="row" style={{ marginTop: '-30px' }}>

                                    <div className='mb-5 align-items-center'>
                                        <label className='mr-2' htmlFor="">Kitchen</label>
                                        <input style={{ border: '#019D9E' }} type="checkbox" id='kitchen' className='mr-3' />
                                    </div>

                                    <div className='mb-5 ml-3 align-items-center'>
                                        <label className='mr-2' htmlFor="">Backyard</label>
                                        <input className='mr-5' style={{ border: '#019D9E' }} id='backyard' type="checkbox" />
                                    </div>

                                    <div className='mb-5 align-items-center'>
                                        <label className='mr-2' htmlFor="">Balcony</label>
                                        <input className='mr-5' style={{ border: '#019D9E' }} id='balcony' type="checkbox" />
                                    </div>

                                    <div className='mb-5 ml-3 align-items-center'>
                                        <label className='mr-2' htmlFor="">Television</label>
                                        <input className='mr-5' style={{ border: '#019D9E' }} id='television' type="checkbox" />
                                    </div>

                                    <div className='mb-5 ml-3 align-items-center'>
                                        <label className='mr-2' htmlFor="">Washing machine</label>
                                        <input className='mr-5' style={{ border: '#019D9E' }} id='washingmachine' type="checkbox" />
                                    </div>

                                    <div className='mb-5 ml-3 align-items-center'>
                                        <label className='mr-2' htmlFor="">Dinning</label>
                                        <input className='mr-5' style={{ border: '#019D9E' }} id='dinning' type="checkbox" />
                                    </div>

                                    <div className='mb-5 ml-3 align-items-center'>
                                        <label className='mr-2' htmlFor="">Swimming pool</label>
                                        <input className='mr-5' style={{ border: '#019D9E' }} id='swimmingpool' type="checkbox" />
                                    </div>

                                    <div className='mb-5 ml-3 align-items-center'>
                                        <label className='mr-2' htmlFor="">Beach view</label>
                                        <input style={{ border: '#019D9E' }} id='beachview' type="checkbox" />
                                    </div>

                                    
                                </div>

                                <div className="row" style={{ marginTop: '-30px' }}>

                                    <div className='mb-5 align-items-center'>
                                        <label className='mr-2' htmlFor="">Refridgerator</label>
                                        <input style={{ border: '#019D9E' }} id="refrigerator" type="checkbox" className='mr-3' />
                                    </div>

                                    <div className='mb-5 ml-3 align-items-center'>
                                        <label className='mr-2' htmlFor="">Car park</label>
                                        <input className='mr-5' style={{ border: '#019D9E' }} id="carpark" type="checkbox" />
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '200px', padding: 10 }} >Property Description</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleUpdate}>

                                <div className="row" style={{ marginTop: '-50px' }}>

                                    <div className='col-lg-12 mb-5'>
                                        <label htmlFor="">Description<span style={{ fontSize: '16px', color: '#EF3061' }}>*</span></label>
                                        <textarea className='form-controls' id='description' rows={5} placeholder="e.g Luxury 3 bedroom flat " style={{ width: '100%' }}></textarea>
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="col-lg-12 mt-5 mb-5 contact-form">
                  <div className="row">
                      <div className="col-lg-12" data-aos="fade-up" data-aos-duration="1000">
                          <div className='th-contact-form'>
                            <p style={{ color: '#019D9E', marginTop: '-20px', background: 'white', fontSize: '18px', marginLeft: 10, width: '150px', padding: 10 }} >Property rules</p>
                            <div className='the-form'>
                                <form onSubmit={this.handleUpdate}>

                                <div className="row" style={{ marginTop: '-50px' }}>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Allow smoking</label>
                                        <select className='form-controls' name="" id="allowsmoking" placeholder="Select property status">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Allow party</label>
                                        <select className='form-controls' name="" id="allowparty" placeholder="Select property status">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Allow pet</label>
                                        <select className='form-controls' name="" id="allowpet" placeholder="Select property status">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Check-in time</label>
                                        <select className='form-controls' name="" id="checkInTime" placeholder="Select property status">
                                            <option value="8am">8am</option>
                                            <option value="9am">9am</option>
                                            <option value="10am">10am</option>
                                            <option value="11am">11am</option>
                                            <option value="12am">12am</option>
                                            <option value="1pm">1pm</option>
                                            <option value="2pm">2pm</option>
                                            <option value="3pm">3pm</option>
                                            <option value="4pm">4pm</option>
                                        </select>
                                    </div>

                                    <div className='col-lg-4 mb-5'>
                                        <label htmlFor="">Check-out time</label>
                                        <select className='form-controls' name="" id="CheckOutTime" placeholder="Select property status">
                                            <option value="8am">8am</option>
                                            <option value="9am">9am</option>
                                            <option value="10am">10am</option>
                                            <option value="11am">11am</option>
                                            <option value="12am">12am</option>
                                            <option value="1pm">1pm</option>
                                            <option value="2pm">2pm</option>
                                        </select>
                                    </div>
                                </div>
                                </form>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className='d-flex justify-content-center mb-4'>
                  <button onClick={this.addNewProperty} style={{ background: '#019D9E', color: 'white', width: '90%', border: '0px', padding: '17px', borderRadius: '10px' }}>save & proceed to picture upload</button>
              </div>
            </>
        );
    }
}
 
export default Contact;