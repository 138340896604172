import React, { Component } from 'react';
import Logo from "../Assets/images/Riz secondary logo.png"
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Twitter from "../Assets/images/Twitter - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import Map from "../Assets/images/Rectangle 285.png"

import Image1 from "../Assets/images/unsplash_yETqkLnhsUI.png"

import Image2 from "../Assets/images/unsplash_yETqkLnhsUI1.png"
import Image3 from "../Assets/images/unsplash_yETqkLnhsUI2.png"
import Image4 from "../Assets/images/unsplash_yETqkLnhsUI3.png"
import Image5 from "../Assets/images/unsplash_yETqkLnhsUI4.png"

import Images from "../Assets/images/clarity_picture-line.png"
import Like from "../Assets/images/Outline/Status/Icon.png"
import Location from "../Assets/images/Outline/Navigation/Icon.png"
import Calendar from "../Assets/images/calendar_1_.png"
import Premium from "../Assets/images/Frame 146.png"
import Vector from "../Assets/images/Vector.png"
import Vector1 from "../Assets/images/Vector2.png"
import Vector2 from "../Assets/images/Vector3.png"
import Vector3 from "../Assets/images/Vector4.png"

import { Link } from 'react-router-dom';

class Rent extends Component {
    state = { 
        activeSearch: "shortlet"    
    } 

    handlSearchChange = async(e) => {
        this.setState({activeSearch: e.target.id})
    }

    render() { 
        return (
            <div>
                <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agents">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>SIGN UP</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agents'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>

                            </ul>
                            <div className='ml-3'>
                                <Link to='/register' style={{ background: '#019D9E', borderRadius: '10px' }}>
                                    <button style={{ background: '#019D9E', borderRadius: '10px' }} className='btn text-white'>SIGN UP</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    

                    
                    
                

                </div>
                    <div className="background-search pb-4">
                        <div className='search-menu'>
                            <div id='shortlet' onClick={this.handlSearchChange} style={{ cursor: 'pointer' }} className={ this.state.activeSearch === "shortlet" ? "active first-card" : "first-card"}>
                                <p id='shortlet'>Shortlet</p>
                            </div>
                            <div id='rent' onClick={this.handlSearchChange} style={{ cursor: 'pointer' }} className={ this.state.activeSearch === "rent" ? "active" : ""}>
                                <p id='rent'>Rent</p>
                            </div>
                            <div id='buy' onClick={this.handlSearchChange} style={{ cursor: 'pointer' }} className={ this.state.activeSearch === "buy" ? "active last-card" : "last-card"}>
                                <p id='buy'>Buy</p>
                            </div>
                        </div>
                        <div className="search-box">
                            <div className='searchbox'>

                            
                                <div className='search-bar'>
                                    <input type="text" className='mt-2' placeholder='Enter Location' />
                                </div>

                                <div className='date-selection d-flex ml-3'>
                                    { this.state.activeSearch === "shortlet" ? 
                                    <div>
                                        <p className='ml-2' htmlFor="">Check in</p>
                                        <input className='search-date mt-2' placeholder="Date" type="date" onfocus="(this.type='date')" />
                                    </div>
                                    : "" }

                                
                                    { this.state.activeSearch === "shortlet" ? 
                                    <div className='ml-4'>
                                        <p className='ml-2' htmlFor="">Check Out</p>
                                    
                                        <input className='search-date mt-2' placeholder="Date" type="date" onfocus="(this.type='date')" />
                                    </div>
                                    : "" }

                                    { this.state.activeSearch === "rent" || this.state.activeSearch === "buy" ? 
                                    <div>
                                        <p className='ml-2' htmlFor="">Property ref number</p>
                                        <select className='form-control mt-2 search-date search-date2' name="" id="">
                                            <option value="">Select type</option>
                                        </select>
                                    </div>
                                    : "" }

                                    { this.state.activeSearch === "rent" || this.state.activeSearch === "buy" ? 
                                    <div className='ml-4'>
                                        <p className='ml-2' htmlFor="">Number of bedroom </p>
                                        <select className='form-control mt-2 search-date search-date2' name="" id="">
                                            <option value="">Select type</option>
                                        </select>
                                    </div>
                                    : "" }
                                </div>

                            
                            </div>
                            <div className='searchbox align-items-center'>
                                <div className='mt-2'>
                                    <p className='ml-2 protype' htmlFor="">Property type</p>
                                    <select className='form-control mt-2 search-date search-date2' name="" id="">
                                        <option value="">Select type</option>
                                    </select>
                                </div>

                                <div className='mt-2 rangeis'>
                                    <p className='ml-2 text-center protype' htmlFor="">Set price range</p>
                                    
                                    <div class="slider mt-2">
                                        <div class="progress"></div>
                                    </div>
                                    <div class="range-input">
                                      <input type="range" class="range-min" min="0" max="10000" value="2500" step="100" />
                                      <input type="range" class="range-max" min="0" max="10000" value="7500" step="100" />
                                    </div>

                                </div>

                                <div className='mt-2 d-flex align-items-center btn-search'>
                                    <button>Search</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <div className='search-result text-center'>
                        <h5>Sorry, we don't currently have any properties that match your search.</h5>
                        <p>Check out results closer to your search ‘Lagos’.</p>
                    </div>


                    <div className="landing-3" style={{ marginTop: '-40px' }}>
                        <div className='desktop-property'>
                    <div className="col-lg-12">
                        <div className="row align-items-center">
                            
                            <div className="col-lg-12">
                                <div className='apartments'>
                                    <div className='d-flex p-0 m-0'>
                                        <div className='col-lg-6 p-0 m-0'>
                                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '500px' }} src={Image1} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '500px' }} src={Image1} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '500px' }} src={Image1} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slides-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>
                                                                                </div>

                                        <div className='apartment-caption col-lg-6 m-0 p-0'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section '>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex mt-4">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-12 mb-4">
                                <div className='apartments'>
                                    <div className='d-flex p-0 m-0'>
                                        <div className='col-lg-6 p-0 m-0'>
                                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '500px' }} src={Image1} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '500px' }} src={Image1} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '500px' }} src={Image1} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slides-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>
                                                                                </div>

                                        <div className='apartment-caption col-lg-6 m-0 p-0'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section '>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex mt-4">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            
                                                    </div>




                            
                        </div>
                    </div>
                    </div>
                                                    <div className='mobile-property'>
                                                        <div className="col-lg-12">
                        <div className="row align-items-center">
                            
                            <div className="col-lg-4 mb-5">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image1} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image1} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image1} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slide-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-4 mb-5">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image1} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image1} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image1} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slide-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-lg-4 mb-5">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image2} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image2} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image2} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slide-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                           

                            

                            <div className="col-lg-4">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControls1" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image3} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image3} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image3} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControls1" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControls1" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slide-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-lg-4">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControls2" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image4} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image4} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image4} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControls2" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControls2" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slide-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            

                            <div className="col-lg-4">
                                <div className='apartment'>
                                    <div>
                                        <div id="carouselExampleControls3" class="carousel slide" data-ride="carousel">
                                        <div class="carousel-inner">
                                          <div class="carousel-item active">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image5} alt="First slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image5} alt="Second slide"/>
                                          </div>
                                          <div class="carousel-item">
                                            <img class="d-block w-100" style={{ height: '330px' }} src={Image5} alt="Third slide"/>
                                          </div>
                                        </div>
                                        <a class="carousel-control-prev" href="#carouselExampleControls3" role="button" data-slide="prev">
                                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Previous</span>
                                        </a>
                                        <a class="carousel-control-next" href="#carouselExampleControls3" role="button" data-slide="next">
                                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span class="sr-only">Next</span>
                                        </a>
                                        </div>
                                        <div className='slide-caption d-flex p-2'>
                                            <div className='ml-auto d-flex'>
                                                <div className='d-flex align-items-center'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Images} alt="" />
                                                   <p className='text-white mr-2'>1/5</p>
                                                </div>
                                                <div className='d-flex align-items-center ml-4 mr-2'>
                                                   <img className='mr-2' style={{ height: '20px' }} src={Like} alt="" />
                                                   <p className='text-white mr-2'>Save</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='apartment-caption'>
                                            <div className='first-apart-section'>

                                            
                                                <p className='apartment-name'>2 bedroom flat / apartment shortlet</p>
                                                <p className='apartment-location mt-2'> <img style={{ height: '15px' }} src={Location} alt="" /> Ikate, Lekki, Lagos</p>
                                                <div className='mt-4'>
                                                    <p className='text-justify'>Luxury in the heart of lekki ikate! this apartment is situated in a secure estate in ikate. this apartment was designed with attention</p>
                                                </div>

                                                <div className='mt-4 more-detail d-flex align-items-center'>
                                                    <p className='mr-2'>More details</p> <img style={{ height: '10px' }} src={Arrow} alt="" />
                                                </div>

                                                <div className='mt-3 more-detail d-flex align-items-center'>
                                                     <img style={{ height: '18px' }} className="mr-2" src={Calendar} alt="" /> <p style={{ fontSize: '13px' }} className='mr-2 text-dark'>Added on  10 August 2022</p>
                                                </div>
                                            </div>
                                            <hr style={{ marginTop: '-2px' }} />
                                            <div className='d-flex second-section'>
                                                <div className='d-flex align-items-center'>
                                                    <h6 className='mr-1'>₦55,000</h6> <p>Per day</p>
                                                </div>
                                                <div className='ml-auto'>
                                                    <img style={{ height: '30px' }} src={Premium} alt="" />
                                                </div>
                                            </div>

                                            <div className="apartment-footer d-flex">
                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector} alt="" />
                                                    <p>2 Bedrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector1} alt="" />
                                                    <p>2 Toilets</p>
                                                </div>

                                                <div className='d-flex align-items-center mr-3'>
                                                    <img className='mr-2' src={Vector2} alt="" />
                                                    <p>2 Bathrooms</p>
                                                </div>

                                                <div className='d-flex align-items-center'>
                                                    <img className='mr-2' src={Vector3} alt="" />
                                                    <p>Car park</p>
                                                </div>
                                            </div>
                                        </div>


                                        </div>

                                    <div>

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                                                    </div>
                
                <footer>
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <img src={Facebook} alt="" />
                        <img src={Twitter} alt="" />
                        <img src={Instagram} alt="" />
                        <img src={Linkedin} alt="" />


                    </div>

                    <p className='mt-4'>© 2022 . All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Features</li>
                        <li className='mt-3'>Partners</li>
                        <li className='mt-3'>Pricing </li>
                        <li className='mt-3'>Product</li>
                        <li className='mt-3'>Support</li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>About Us</li>
                        <li className='mt-3'>Agents</li>
                        <li className='mt-3'>Blog </li>
                        <li className='mt-3'>Media</li>
                        <li className='mt-3'>Contact Us</li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                        
                    </ul>

                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                        <input  placeholder='Email Address' type="text" />
                        <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                            <img style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                        </div>
                    </div>
                </div> 
            </footer>
            </div>
        );
    }
}
 
export default Rent;