import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"
import House from "../Assets/images/Vector22.png"
import House1 from "../Assets/images/akar-icons_network.png"
import House2 from "../Assets/images/carbon_user-avatar.png"
import axios from 'axios';
import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import { ToastContainer, toast } from "react-toastify"
import { BallTriangle } from  'react-loader-spinner'

class Contact extends Component {
    state = { 
        img1: House,
        img2: House1,
        img3: House2,
        category1: "",
        category2: "",
        button: true,
        category: "",
        category3: "",
        display: "none"
    } 

    async componentDidMount(){
        const user = JSON.parse(sessionStorage.getItem('userDetails'))
        if(user){
          this.props.history.push('/dashboard');  
        }
    }

    handleSelecta = (e) => {
        this.setState({ category1: "", category2: "", category3: "", img1: House, img2: House1, img3: House2 })
        const id = e.target.id;
        if(id == "category1"){
            sessionStorage.setItem("user", "agent");
            this.props.history.push('/register');
        }

        if(id == "category2"){
            sessionStorage.setItem("user", "affiliate");
            this.props.history.push('/register');
        }

        if(id == "category3"){
            sessionStorage.setItem("user", "client");
            this.props.history.push('/register');
        }
    }

    handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }

        this.setState({ display: "flex" })
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
            this.setState({ display: "none" })
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
                this.setState({ display: "none" })
            }else {
                toast.error(newsletter.data.statusMessage);
                this.setState({ display: "none" })
            }
        }else {
            toast.error("Please input a valid email address");
            this.setState({ display: "none" })
        }
    }

    render() { 
        return (
            <>
            <ToastContainer />
            <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Affiliates
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>Join Now</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: 'black' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>
                                <li><Link style={{ color: 'black' }} to='/login'>Login</Link></li>

                            </ul>
                            <div className='ml-3'>
                                <Link to='/category' style={{ background: '#019D9E', borderRadius: '10px' }}>
                                    <button style={{ background: '#019D9E', fontSize: '12px', borderRadius: '10px' }} className='btn text-white'>Join Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='back-arrow pt-5'>
                    <Link to="/" style={{ cursor: 'pointer', display: 'flex' }}>
                        <i class='bx bx-chevron-left' ></i> <p className='ml-2'>Back</p>
                    </Link>
                </div>

                <div className="col-lg-12 d-flex justify-content-center">
                    <div className="category-screen text-center" data-aos="fade-up" data-aos-duration="3000">
                        <div className='category-container'>
                            <h5 className='text-center'>Choose how you want to use Riz estate</h5>
                            <p className='text-center'>Select how you want to riz estate platform </p>
                        </div>

                        <div className='container-category'>
                                <div className='sub-cat' id='category1' onClick={this.handleSelecta}>
                                    <div onClick={this.handleSelecta} id='category1'>
                                        <img onClick={this.handleSelecta} src={this.state.img1} alt="House" id='category1' />
                                        <div onClick={this.handleSelecta} className='mt-3' id='category1'>
                                            <h5 id='category1' onClick={this.handleSelecta}>Agent</h5>
                                            <p id='category1' onClick={this.handleSelecta}>Sign up as an estate agent</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='sub-cat' id='category2' onClick={this.handleSelecta}>
                            <div id='category2' onClick={this.handleSelecta}>
                                    <img id='category2' onClick={this.handleSelecta} src={this.state.img2} alt="House" />
                                    <div id='category2' onClick={this.handleSelecta} className='mt-3'>
                                        <h5 id='category2' onClick={this.handleSelecta}>Affiliate</h5>
                                        <p id='category2' onClick={this.handleSelecta}>Sign up as an affiliate</p>
                                    </div>
                                </div>
                            </div>

                            <div  className='sub-cat' id='category3' onClick={this.handleSelecta}>
                            <div id='category3' onClick={this.handleSelecta}>
                                    <img id='category3' onClick={this.handleSelecta} src={this.state.img3} alt="House" />
                                    <div id='category3' onClick={this.handleSelecta} className='mt-3'>
                                        <h5 id='category3' onClick={this.handleSelecta}>Client</h5>
                                        <p id='category3' onClick={this.handleSelecta}>Sign up as a client</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    

                    </div>

                </div>
<footer data-aos="fade-up" data-aos-duration="1000" className='mt-5'>
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>Riz Estate © 2023. All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
                
            </>
        );
    }
}
 
export default Contact;