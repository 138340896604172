import axios from 'axios';
import React, { Component } from 'react';
import {toast, ToastContainer } from "react-toastify";

class Contact extends Component {
    state = {  } 

    async componentDidMount(){
        const props = this.props;
        const id = props.match.params.id
        const sendRequest = await axios.put(`https://alim.com.ng/verifymail`, {id: String(id)});

        if(sendRequest.data.statusCode === 200){
            window.location.href = "/login"
            sessionStorage.setItem('activate', 'yes')
        }else {
            window.location.href = "/login"
            sessionStorage.setItem('activate', 'no')
        }
    }
    render() { 
        return (
            <>
                <ToastContainer />
            </>
        );
    }
}
 
export default Contact;