import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LogoGreen from "../Assets/images/Riz primary logo.png"
import Logo from "../Assets/images/Riz secondary logo.png"
import { BallTriangle } from  'react-loader-spinner'
import Arrow from "../Assets/images/Arrow 4.png"
import Facebook from "../Assets/images/Facebook - Original.png"
import Instagram from "../Assets/images/Instagram - Original.png"
import Linkedin from "../Assets/images/LinkedIn - Original.png"
import Landing5 from "../Assets/images/Rectangle 281.png"

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';

class Contact extends Component {
    state = { 
        display: "none"
    } 

    handleNewsLetter = async () => {
        const payload = {
            email: document.querySelector('#newslettermail').value
        }

        this.setState({ display: "flex" })
        if(document.querySelector('#newslettermail').value == ""){
            toast.error("Please input an email address")
            this.setState({ display: "none" })
        }else if(payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
        {
            const newsletter = await axios.post('https://alim.com.ng/newsletter', payload);
            if(newsletter.data.statusCode == 200){
                toast.success(newsletter.data.statusMessage);
                this.setState({ display: "none" })
            }else {
                toast.error(newsletter.data.statusMessage);
                this.setState({ display: "none" })
            }
        }else {
            toast.error("Please input a valid email address");
            this.setState({ display: "none" })
        }
    }

    handleSelect = async () => {
        sessionStorage.setItem("user", "agent");
        this.props.history.push('/register');
    }
    render() { 
        return (
            <>
            <div style={{ backgroundColor: 'rgba(0,0,0,0.2)', height: "100vh", width: '100%', position: "fixed", zIndex: 1, display: this.state.display }} className="justify-content-center align-items-center">
                <BallTriangle height={100} width={100} radius={5} color="#019D9E" ariaLabel="ball-triangle-loading" wrapperClass={{}} wrapperStyle="" visible={true} />
            </div>
            <ToastContainer />
            <div className='mobile-nav'>
                    <nav className="navbar align-items-center complete-nav navbar-expand-lg">
                        <Link className="navbar-brand" to="/"><img style={{ height: '40px', }} className='p-1 navbar-image' src={LogoGreen} alt="Logo" /></Link>    
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> <i className='bx bx-menu' style={{ fontSize: '30px', color: '#019D9E' }}></i> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <ul className="navbar-nav mt-3">
                                  <Link className='text-center' style={{textDecoration: 'none'}} to="/shortlet">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SHORTLET
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/rent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       RENT
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/sales">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       SALES
                                    </li>
                                  </Link>

                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/agent">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       agents
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/affiliates">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       Affiliates
                                    </li>
                                  </Link>
                                  <Link className='mt-2' style={{textDecoration: 'none'}} to="/contact">
                                    <li className="nav-item font-weight-bold text-center text-dark">
                                       CONTACT US
                                    </li>
                                  </Link>
                                   <div className='d-flex justify-content-center'>
                                        <Link className='mt-2' to='/register'>
                                            <button className='up-button' style={{ paddingRight: '20px', paddingLeft: '20px' }}>Join Now</button>
                                        </Link>
                                   </div>
                            </ul>
    
                        </div>
                    </nav>
                </div>

                <div className="landings">
                    <div className='desktop-nav'>
                        <div className="navbar pt-4">
                            <div className="navbar-brand">
                                <Link to="/">
                                    <img src={LogoGreen} style={{ height: '40px' }} alt="Riz Estate" />
                                </Link>
                            </div>
                            <ul className='ml-auto pt-3 d-flex align-items-center'>
                                <li><Link style={{ color: 'black' }} to='/shortlet'>SHORTLET</Link></li>
                                <li><Link style={{ color: 'black' }} to='/rent'>RENT </Link></li>
                                <li><Link style={{ color: 'black' }} to='/sales'>SALES </Link></li>
                                <li><Link style={{ color: '#019D9E' }} to='/agent'>agents</Link></li>
                                <li><Link style={{ color: 'black' }} to='/affiliates'>affiliates</Link></li>
                                <li><Link style={{ color: 'black' }} to='/contact'>CONTACT US</Link></li>
                                <li><Link style={{ color: 'black' }} to='/login'>Login</Link></li>

                            </ul>
                            <div className='ml-3'>
                                <Link to='/category' style={{ background: '#019D9E', borderRadius: '10px' }}>
                                    <button style={{ background: '#019D9E', fontSize: '12px', borderRadius: '10px' }} className='btn text-white'>Join Now</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 about-wrapper'>
                    <div className='text-center' data-aos="fade-up" data-aos-duration="1000">
                        <h5>Agents</h5>
                        <p className='mt-2'>Get to understands what it takes to be an agent at</p>
                        <p className='mt-1'>RIZ ESTATE</p>
                    </div>
                </div>

                <div className='brief-content'>
                    <h5 data-aos="fade-up" data-aos-duration="1000">Become an Agent</h5>
                    <p data-aos="fade-up" data-aos-duration="1000" className='mt-2'>We help to list and sell your room faster.</p>                   
                </div>

                <div className='landing-5 mb-4'>
    <div className="landing-5-10">


    <div className='landing-5-flex'>

        <div className='why-chooses ' data-aos="fade-right" data-aos-duration="1000">
            <div className='mt-3 mb-3'>
                <h5 >
                    Become an Agent at Riz Estate
                </h5>

                <p>Get specialist advice from local experts across all types of residential, commercial and rural property.</p>
                <div onClick={this.handleSelect} to="/category">
                    <button className='mt-4'>SIGN UP TODAY</button>
                </div>
            </div>
        </div>

        <div id='div-imgs' data-aos="fade-left" data-aos-duration="1000">
            <img id='imgs' src={Landing5} alt="" />
        </div>
    </div>
    </div>
</div>
    

                

        <footer data-aos="fade-up" data-aos-duration="1000">
                <div className="footer-1 mb-5">
                    <img style={{ height: '40px' }} src={Logo} alt="" />
                    <p className='mt-5'>is No 1 estate property site in Nigeria. We provide users with the best search experience, we connect buyers & sellers at a speed of light usually within 24hours</p>

                    <div className='d-flex social'>
                        <a target="__blank" href="https://www.facebook.com/rizpremium">
                            <img src={Facebook} alt="" />
                        </a>
                        <a target="__blank" href="https://www.instagram.com/rizestatepro/">
                            <img src={Instagram} alt="" />
                        </a>
                        <a target="__blank" href="https://www.linkedin.com/company/ripremium-global-empire/">
                            <img src={Linkedin} alt="" />
                        </a>


                    </div>

                    <p className='mt-4'>Riz Estate © 2023. All rights reserved.</p>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Take a tour</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/shortlet">Shortlet</a></li>
                        <li className='mt-3'><a className='text-white' href="/category">Register</a></li>
                        <li className='mt-3'><a className='text-white' href="/login">Login</a></li>
                    </ul>
                </div>
                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Our Company</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'><a className='text-white' href="/about">About Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/contact">Contact Us</a></li>
                        <li className='mt-3'><a className='text-white' href="/agent">Agents</a></li>
                    </ul>
                </div>

                <div className='footer-left'>
                    <h6 className='mt-4' style={{ marginLeft: '38px' }}>Subscribe</h6>
                    <ul className='mt-4'>
                        <li className='mt-3'>Subscribe to get latest property, blog news from us</li>
                    </ul>
                    <div className='ml-5 d-flex align-items-center' style={{ width: '100%' }}>
                            <input id='newslettermail' placeholder='Email Address' type="text" required />
                            <div className='d-flex align-items-center pr-2 pl-2' style={{ background: '#019D9E', borderRadius: '50px', marginLeft: '-50px', height: '35px' }}>
                                <div style={{ cursor: 'pointer' }}>
                                    <img onClick={this.handleNewsLetter} style={{ height: '10px', width: '20px' }} src={Arrow} alt="" />
                                </div>
                            </div>
                    </div>
                </div> 
            </footer>
            </>
        );
    }
}
 
export default Contact;